import Vue from "vue";
import App from "@/app/entry/App.vue";
import router from "@/_router";
import store from "@/_store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VueMoment from "vue-moment";
import vueDebounce from "vue-debounce";
import { initializeApp } from "firebase/app";
import firebaseConfig from "@/_config/firebase";
import VueGeolocation from "vue-browser-geolocation";
import Directives from "@/_directives";
// import '@/sass/puntofarma/app.scss';
// import it from "moment/locale/it";
// import { getAnalytics } from "firebase/analytics";

require("moment/locale/it");
require("@/_sass/" + process.env.VUE_APP_SCOPE + "/app.scss");

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueGeolocation);
Vue.use(VueAxios, axios);
Vue.use(VueMoment);
Vue.use(vueDebounce);
Vue.use(Directives);
Vue.config.productionTip = false;

//Comment inutile
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseApp);

navigator.serviceWorker
  .register("/" + process.env.VUE_APP_SCOPE + "/firebase-messaging-sw.js")
  .then((registration) => {
    console.log("registration", registration);
  })
  .catch((err) => {
    console.log(err);
  });

// if (process.env.VUE_APP_ENVIRONEMENT !== "prod") {
//   require("./sass/puntofarma/app.scss");
// }

new Vue({
  router,
  store,
  render: (h) => h(App),
  data() {
    return {
      menuLeft: false,
      isMobile: window.innerWidth <= 576,
      isCollapsed: window.innerWidth <= 767,
      isTablet: window.innerWidth > 576 && window.innerWidth < 1024,
      isLaptop: window.innerWidth >= 1024 && window.innerWidth <= 1440,
      isTabletOrLaptop: window.innerWidth <= 1440 && window.innerWidth > 576,
      isMobileOrTablet: window.innerWidth < 1024,
      prestazioniOnly: process.env.VUE_APP_PRESTAZIONI_ONLY,
      scope: process.env.VUE_APP_SCOPE,
      appName: process.env.VUE_APP_APP_NAME,
    };
  },
}).$mount("#app");
