<template>
  <b-row
    class="header__shadow bg-secondary text-white p-4 mb-3 align-items-center"
  >
    <h1 class="pl-2 pl-lg-4 py-2 py-lg-3 py-xl-4">
      {{ routeLabel }}
    </h1>
    <b-col>
      <b-row cols="1" class="justify-content-end">
        <b-col class="text-right px-0">
          <b-dropdown
            id="header-submenu"
            variant="secondary no__hover"
            class="m-md-2 text-capitalize text-right pr-0"
          >
            <template #button-content>
              <strong class="text-capitalize pr-2">{{ welcomeName }}</strong>
            </template>
            <b-dropdown-item>
              <span
                class="d-flex align-items-center pl-1 py-1 general_border_3 point"
                @click.prevent="$bvModal.show('logout-confirm')"
              >
                <b-icon font-scale="1.3" icon="door-open"></b-icon>
                <span class="mb-0 ml-2 text-capitalize">Esci</span>
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
        <b-col class="text-right px-0" v-if="$root.scope === 'concura'">
          <HeaderRecapSottoscrizioniPaziente />
        </b-col>

        <b-col class="text-right px-0 fs-5">
          <a
            :href="publicArea"
            class="mr-3 point text-right"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span class="text-white text-underlined mr-1">
              {{ publicArea.replace("https://", "www.") }}
            </span>
            <b-icon
              icon="box-arrow-up-right"
              class="mt-n1point text-white"
              font-scale="1.45"
            ></b-icon>
          </a>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import { mapState } from "vuex";
import { CITTADINO_ROUTE_NAMES } from "@/_utils/constants.js";
import HeaderRecapSottoscrizioniPaziente from "@/app/modules/sottoscrizioni/components/HeaderRecapSottoscrizioniPaziente.vue";

export default {
  name: "CittadinoTopHeader",
  components: {
    HeaderRecapSottoscrizioniPaziente,
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    welcomeName() {
      if (this.user && this.user.anagrafica) {
        return `${this.user.anagrafica.name} ${this.user.anagrafica.surname}`;
      }
      return this.user.username;
    },
    routeLabel() {
      try {
        const label = this.routeNames[this.$route.name];
        if (label === undefined) {
          throw "label not found";
        }
        if (this.$route.name === "appuntamenti") {
          return label[this.$route.params.type];
        } else if (this.$route.name === "teleconsulti") {
          return label[this.$route.params.subsection];
        }
        return label;
      } catch (err) {
        return `Ciao, ${this.welcomeName}`;
      }
    },
  },
  data() {
    return {
      routeNames: CITTADINO_ROUTE_NAMES,
      publicArea: process.env.VUE_APP_PUBLIC_AREA,
    };
  },
};
</script>
