<template>
  <b-container
    ref="navmain"
    fluid
    class="bg-white shadow_2 h-100 px-0 text-font-grey d-flex flex-column"
    :class="
      menuVisible && wlistener < 768 ? 'mobile-menu-visible' : 'mobile-menu'
    "
  >
    <transition-group
      name="slideside"
      mode="out-in"
      class="flex-grow-1 d-flex flex-column"
    >
      <!-- Navigation Desktop -->
      <template v-if="wlistener >= 768">
        <b-row align-v="center" class="px-0" key="logo-row">
          <b-col
            class="px-1 py-3 mt-2 text-center"
            cols="6"
            md="12"
            :class="menuIsCompact && !$root.isCollapsed ? 'pppp' : 'ml-2'"
          >
            <router-link :to="{ name: 'Home' }" class="">
              <img
                v-if="menuIsCompact && !$root.isCollapsed"
                :src="require(`@/assets/${$root.scope}/dot-logo.svg`)"
                alt="appLogo"
                class="px-lg-2"
                style="max-width: 75px"
                key="icon_logo"
              />
              <img
                v-else
                :src="require(`@/assets/${$root.scope}/logo.svg`)"
                alt="appLogo"
                key="full_logo"
                style="max-width: 120px"
              />
            </router-link>
          </b-col>
        </b-row>
        <b-row cols="1" class="px-0" key="menu-row" v-if="menuVisible">
          <b-col
            class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-3 pt-0 text-left"
            key="desk_ham"
          >
            <p class="pb_15 nav__item bg-white general_border_3">
              <span
                class="d-flex align-items-center pl-1 py-1 general_border_3 point pt-3"
                @click="handleMenuSize"
              >
                <b-icon font-scale="1.7" icon="filter-left"></b-icon>
                <span class="mb-0 ml-2"></span>
                <span v-if="!menuIsCompact" class="text-font-light-grey"
                  >MENU</span
                >
              </span>
            </p>
          </b-col>
          <b-col class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left">
            <p class="pb_15 nav__item bg-white general_border_3">
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border_3"
                :class="
                  this.$route.name === 'CittadinoHome'
                    ? 'router-link-exact-active'
                    : ''
                "
                :to="{ name: 'Home' }"
              >
                <b-icon font-scale="1.3" icon="house-door"></b-icon>
                <span class="mb-0 ml-2">Homepage</span>
              </router-link>
            </p>
            <p
              class="pb_15 nav__item bg-white general_border_3"
              v-if="activeRefs.includes(1) || activeRefs.includes(2)"
            >
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border_3"
                :to="{ name: agendaSet.routeName }"
              >
                <b-icon font-scale="1.3" :icon="agendaSet.logo"></b-icon>
                <span class="mb-0 ml-2">{{ agendaSet.name }}</span>
              </router-link>
            </p>
            <p class="pb_15 nav__notifications nav__item">
              <NotificationCenter />
            </p>
          </b-col>
          <b-col class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left">
            <p class="text-font-light-grey">
              <span class="text-white">SERVIZI</span>
            </p>
            <template v-for="(service, ind) in filteredActiveServices">
              <p
                v-if="activeRefs.includes(service.position_ref)"
                class="pb_15 nav__item"
                :class="service.submenu ? 'bg-mdm-light-grey' : 'bg-white'"
                :key="ind"
              >
                <template
                  v-if="
                    service.submenu &&
                    !(isBlogService(service) && !showProjectMenu)
                  "
                >
                  <span
                    :key="'sub-m-' + service.routeName"
                    class="d-flex align-items-center pl-1 py-1 general_border_3 point"
                    :class="
                      $route.name === service.routeName
                        ? 'router-link-exact-active'
                        : ''
                    "
                    @click="service.submenuVisible = !service.submenuVisible"
                  >
                    <b-icon font-scale="1.3" :icon="service.logo"></b-icon>
                    <span class="mb-0 ml-2">{{
                      service.name.replace(/\s/g, "&nbsp;")
                    }}</span>
                    <b-icon
                      font-scale="1"
                      :icon="
                        service.submenuVisible ? 'chevron-up' : 'chevron-down'
                      "
                      class="mr-1 ml-auto"
                    ></b-icon>
                  </span>
                  <b-collapse
                    class="mt-1"
                    :id="service.name + '-sub'"
                    v-model="service.submenuVisible"
                  >
                    <template v-for="rt in service.subroutes">
                      <p
                        v-if="
                          !rt.position_ref ||
                          (rt.position_ref &&
                            activeRefs.includes(rt.position_ref))
                        "
                        :key="'sub-' + rt.name"
                        class="mb-1"
                      >
                        <router-link
                          v-if="!(isBlogService(service) && !rt.hashid)"
                          class="d-flex align-items-center pl-1 py-1 general_border_3 align-items-center text-secondary"
                          :class="
                            $route.name === service.routeName &&
                            (rt.params.type
                              ? $route.params.type === rt.params.type
                              : $route.params.section === rt.params.section)
                              ? 'terz_bg_gradient text-white submenu__active__class'
                              : ''
                          "
                          :to="{
                            name: service.routeName,
                            params: isBlogService(service)
                              ? {
                                  section: 'panoramica',
                                  hashprogetto: rt.hashid,
                                }
                              : rt.params,
                          }"
                          exact-active-class="terz_bg_gradient text-white submenu__active__class"
                        >
                          <b-iconstack font-scale="1.3">
                            <b-icon
                              stacked
                              icon="circle"
                              variant="mdm-light-grey"
                              scale="0.55"
                            ></b-icon>
                            <b-icon
                              stacked
                              icon="circle"
                              variant="extra-light"
                            ></b-icon>
                          </b-iconstack>
                          <span class="mb-0 ml-2">{{
                            rt.name.replace(/\s/g, "&nbsp;")
                          }}</span>
                        </router-link>
                      </p>
                    </template>
                  </b-collapse>
                </template>
                <template v-else>
                  <router-link
                    class="d-flex align-items-center pl-1 py-1 general_border_3"
                    :to="redirectRoute(service)"
                  >
                    <b-icon font-scale="1.3" :icon="service.logo"></b-icon>
                    <span class="mb-0 ml-2">{{
                      service.name.replace(/\s/g, "&nbsp;")
                    }}</span>
                  </router-link>
                </template>
              </p>
            </template>
          </b-col>
          <b-col class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left">
            <p class="text-font-light-grey">
              <span class="text-white">PAGINE</span>
            </p>
            <p class="pb_15 nav__item bg-white general_border_3">
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border_3"
                :to="{ name: 'profilo cittadino' }"
              >
                <b-avatar
                  v-if="user && user.profile_pic"
                  variant="white"
                  :src="user.profile_pic"
                  size="sm"
                  rounded
                >
                </b-avatar>
                <b-icon
                  v-else
                  font-scale="1.3"
                  :icon="
                    this.$route.name === 'profilo cittadino'
                      ? 'person-fill'
                      : 'person'
                  "
                ></b-icon>
                <span class="mb-0 ml-2 text-capitalize">{{
                  welcomeName.replace(/\s/g, "&nbsp;")
                }}</span>
              </router-link>
            </p>

            <p
              class="pb_15 nav__item bg-mdm-light-grey"
              v-if="userSubscriptionNeeds[user.type]"
            >
              <template v-if="acquisti.submenu">
                <span
                  :key="'sub-m-' + acquisti.routeName"
                  class="d-flex align-items-center pl-1 py-1 general_border_3 point"
                  :class="
                    $route.name === acquisti.routeName
                      ? 'router-link-exact-active'
                      : ''
                  "
                  @click="acquisti.submenuVisible = !acquisti.submenuVisible"
                >
                  <b-icon font-scale="1.3" :icon="acquisti.logo"></b-icon>
                  <span class="mb-0 ml-2">{{
                    acquisti.name.replace(/\s/g, "&nbsp;")
                  }}</span>
                  <b-icon
                    font-scale="1"
                    :icon="
                      acquisti.submenuVisible ? 'chevron-up' : 'chevron-down'
                    "
                    class="mr-1 ml-auto"
                  ></b-icon>
                </span>
                <b-collapse
                  class="mt-1"
                  :id="acquisti.name + '-sub'"
                  v-model="acquisti.submenuVisible"
                >
                  <p
                    v-for="rt in acquisti.subroutes"
                    :key="'sub-' + rt.name"
                    class="mb-1"
                  >
                    <router-link
                      class="d-flex align-items-center pl-1 py-1 general_border_3 align-items-center text-secondary"
                      :to="{
                        name: acquisti.routeName,
                        params: { section: rt.path },
                      }"
                      exact-active-class="terz_bg_gradient text-white submenu__active__class"
                    >
                      <b-iconstack font-scale="1.3">
                        <b-icon
                          stacked
                          icon="circle"
                          variant="mdm-light-grey"
                          scale="0.55"
                        ></b-icon>
                        <b-icon
                          stacked
                          icon="circle"
                          variant="extra-light"
                        ></b-icon>
                      </b-iconstack>
                      <span class="mb-0 ml-2">{{
                        rt.name.replace(/\s/g, "&nbsp;")
                      }}</span>
                    </router-link>
                  </p>
                </b-collapse>
              </template>
              <template v-else>
                <router-link
                  class="d-flex align-items-center pl-1 py-1 general_border_3"
                  :to="{
                    name: acquisti.routeName,
                  }"
                >
                  <b-icon font-scale="1.3" :icon="acquisti.logo"></b-icon>
                  <span class="mb-0 ml-2">{{
                    acquisti.name.replace(/\s/g, "&nbsp;")
                  }}</span>
                </router-link>
              </template>
            </p>

            <p class="pb_15 nav__item bg-white general_border_3">
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border_3"
                :to="{ name: impostazioniSet.routeName }"
              >
                <b-icon font-scale="1.3" :icon="impostazioniSet.logo"></b-icon>
                <span class="mb-0 ml-2">{{ impostazioniSet.name }}</span>
              </router-link>
            </p>
            <p class="pb_15 nav__item bg-white general_border_3">
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border_3"
                :to="{ name: 'guida cittadino' }"
              >
                <b-icon
                  font-scale="1.3"
                  :icon="
                    this.$route.name === 'guida'
                      ? 'info-circle-fill'
                      : 'info-circle'
                  "
                ></b-icon>
                <span class="mb-0 ml-2">Guida</span>
              </router-link>
            </p>
            <SendHelpRequest />
          </b-col>
          <!-- <b-col class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left">
            <p class="text-font-light-grey"></p>

            <p class="pb_15 nav__item bg-white general_border_3">
              <span
                class="d-flex align-items-center pl-1 py-1 general_border_3 point"
                @click.prevent="$bvModal.show('logout-confirm')"
              >
                <b-icon font-scale="1.3" icon="door-open"></b-icon>
                <span class="mb-0 ml-2 ">Esci</span>
              </span>
            </p>
          </b-col> -->
        </b-row>
      </template>
      <!-- Navigation Mobile -->
      <template v-else>
        <b-row
          align-v="center"
          class="px-0 shadow_2 py-1 general_border_3 w-100"
          key="logo-row"
        >
          <template v-if="menuVisible">
            <b-col
              key="mobile_ham"
              class="text-left"
              cols="2"
              @click="menuVisible = !menuVisible"
            >
              <b-icon
                font-scale="2.5"
                icon="arrow-left-circle"
                variant="secondary"
              ></b-icon>
            </b-col>
            <b-col cols="8" class="px-1 py-2 my-1 text-center" offset="-2">
              <router-link
                :to="{ name: 'Home' }"
                :class="menuIsCompact && !$root.isCollapsed ? '' : 'pl-4'"
              >
                <img
                  v-if="menuIsCompact && !$root.isCollapsed"
                  :src="require(`@/assets/${$root.scope}/logo.svg`)"
                  alt="appLogo"
                  class="px-lg-2"
                  style="max-width: 75px"
                  key="icon_logo"
                />
                <img
                  v-else
                  :src="require(`@/assets/${$root.scope}/logo.svg`)"
                  alt="appLogo"
                  key="full_logo"
                  style="max-width: 55px"
                />
              </router-link>
            </b-col>
          </template>
          <template v-else>
            <b-col cols="10" class="px-1 py-2 my-1 text-left">
              <router-link
                :to="{ name: 'Home' }"
                :class="{ 'pl-4': !$root.isCollapsed }"
              >
                <img
                  v-if="menuIsCompact && !$root.isCollapsed"
                  :src="require(`@/assets/${$root.scope}/logo.svg`)"
                  alt="appLogo"
                  class="px-lg-2"
                  style="max-width: 55px"
                  key="icon_logo"
                />
                <img
                  v-else
                  :src="require(`@/assets/${$root.scope}/logo.svg`)"
                  alt="appLogo"
                  key="full_logo"
                  style="max-width: 55px"
                />
              </router-link>
            </b-col>
            <b-col
              key="mobile_ham"
              v-if="wlistener < 768"
              class="text-right"
              cols="2"
              @click="menuVisible = !menuVisible"
            >
              <b-icon
                font-scale="2.8"
                icon="filter-left"
                variant="secondary"
              ></b-icon>
            </b-col>
          </template>
        </b-row>
        <div
          class="d-flex flex-column flex-grow-1 justify-content-around px-0"
          key="menu-row"
          v-if="menuVisible"
        >
          <div class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-3 text-left">
            <section
              class="general_border_3 py-2 general_hover nav__item"
              :class="
                $route.path === '/cittadino/home'
                  ? 'bg-secondary text-white'
                  : ''
              "
            >
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border_3 mobile-nav-tab"
                :class="$route.path === '/cittadino/home' ? 'text-white' : ''"
                :to="{ name: 'Home' }"
              >
                <b-icon font-scale="1.3" icon="house-door"></b-icon>
                <span class="mb-0 ml-2">Homepage</span>
              </router-link>
            </section>
            <section
              v-if="activeRefs.includes(1) || activeRefs.includes(2)"
              class="general_border_3 py-2 general_hover nav__item"
              :class="
                $route.name === agendaSet.routeName
                  ? 'bg-secondary text-white'
                  : ''
              "
            >
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border_3"
                :class="{
                  'bg-secondary text-white mobile-nav-tab':
                    $route.name === agendaSet.routeName,
                }"
                :to="{ name: agendaSet.routeName }"
              >
                <b-icon font-scale="1.3" :icon="agendaSet.logo"></b-icon>
                <span class="mb-0 ml-2">{{ agendaSet.name }}</span>
              </router-link>
            </section>
            <p class="general_border_3 py-2 general_hover nav__item">
              <NotificationCenter />
              <!-- <span class="mb-0 ml-2">Notifiche</span> -->
            </p>
          </div>

          <section class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-3 text-left">
            <p class="text-font-light-grey">
              <span class="text-white">SERVIZI</span>
            </p>
            <template v-for="(service, ind) in filteredActiveServices">
              <div
                v-if="activeRefs.includes(service.position_ref)"
                class="general_border_3 py-2 general_hover nav__item"
                :class="
                  $route.name === service.routeName
                    ? 'bg-secondary text-white'
                    : ''
                "
                :key="ind"
              >
                <template
                  v-if="
                    service.submenu &&
                    !(isBlogService(service) && !showProjectMenu)
                  "
                >
                  <template v-for="rt in service.subroutes">
                    <p
                      :key="'sub-' + rt.name"
                      class="mb-1"
                      v-if="
                        !rt.position_ref ||
                        (rt.position_ref &&
                          activeRefs.includes(rt.position_ref))
                      "
                    >
                      <router-link
                        v-if="!(isBlogService(service) && !rt.hashid)"
                        class="d-flex align-items-center pl-1 py-1 general_border_3"
                        :class="
                          $route.name === service.routeName &&
                          (rt.params?.type
                            ? $route.params.type === rt.params?.type
                            : $route.params.section === rt.params?.section)
                            ? 'text-white bg-secondary'
                            : ''
                        "
                        :to="{
                          name: service.routeName,
                          params: isBlogService(service)
                            ? { section: 'panoramica', hashprogetto: rt.hashid }
                            : rt.params,
                        }"
                        exact-active-class="text-white bg-secondary"
                      >
                        <b-icon font-scale="1.3" :icon="service.logo"></b-icon>
                        <span class="mb-0 ml-2">
                          {{ service.name.replace(/\s/g, "&nbsp;") }} -
                          {{ rt.name.replace(/\s/g, "&nbsp;") }}</span
                        >
                      </router-link>
                    </p>
                  </template>
                  <!-- </b-collapse> -->
                </template>
                <template v-else>
                  <router-link
                    class="d-flex align-items-center pl-1 py-1 general_border_3"
                    :to="redirectRoute(service)"
                  >
                    <b-icon font-scale="1.3" :icon="service.logo"></b-icon>
                    <span class="mb-0 ml-2">{{
                      service.name.replace(/\s/g, "&nbsp;")
                    }}</span>
                  </router-link>
                </template>
                <!-- <router-link
                  class="d-flex align-items-center pl-1 py-1 general_border_3 mobile-nav-tab"
                  :class="{
                    'bg-secondary text-white':
                      $route.name === service.routeName,
                  }"
                  :to="redirectRouteMobile(service)"
                >
                  <b-icon font-scale="1.3" :icon="service.logo"></b-icon>
                  <span class="mb-0 ml-2">{{ service.name }} </span>
                </router-link> -->
              </div>
            </template>
          </section>
          <section class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-3 text-left">
            <div
              class="general_border_3 py-2 general_hover nav__item"
              :class="
                $route.name === 'profilo cittadino'
                  ? 'bg-secondary text-white'
                  : ''
              "
            >
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border_3"
                :class="{
                  'bg-secondary text-white mobile-nav-tab':
                    $route.name === 'profilo cittadino',
                }"
                :to="{ name: 'profilo cittadino' }"
              >
                <b-avatar
                  v-if="user && user.profile_pic"
                  variant="white"
                  :src="user.profile_pic"
                  size="sm"
                  rounded
                >
                </b-avatar>
                <b-icon
                  v-else
                  font-scale="1.3"
                  :icon="
                    this.$route.name === 'profilo cittadino'
                      ? 'person-fill'
                      : 'person'
                  "
                ></b-icon>

                <span class="mb-0 ml-2 text-capitalize">{{
                  welcomeName.replace(/\s/g, "&nbsp;")
                }}</span>
              </router-link>
            </div>
            <div
              v-if="userSubscriptionNeeds[user.type]"
              class="general_border_3 py-2 general_hover nav__item"
              :class="
                $route.name === acquisti.routeName
                  ? 'bg-secondary text-white'
                  : ''
              "
            >
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border_3"
                :class="{
                  'bg-secondary text-white mobile-nav-tab':
                    $route.name === acquisti.routeName,
                }"
                :to="{
                  name: 'sottoscrizioni paziente',
                  params: { section: 'gestione' },
                }"
              >
                <b-icon font-scale="1.3" :icon="acquisti.logo"></b-icon>
                <span class="mb-0 ml-2">{{ acquisti.name }}</span>
              </router-link>
            </div>
            <div
              class="general_border_3 py-2 general_hover nav__item"
              :class="
                $route.name === impostazioniSet.routeName
                  ? 'bg-secondary text-white'
                  : ''
              "
            >
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border_3"
                :class="{
                  'bg-secondary text-white mobile-nav-tab':
                    $route.name === impostazioniSet.routeName,
                }"
                :to="{ name: impostazioniSet.routeName }"
              >
                <b-icon font-scale="1.3" :icon="impostazioniSet.logo"></b-icon>
                <span class="mb-0 ml-2">{{ impostazioniSet.name }}</span>
              </router-link>
            </div>
            <div
              class="general_border_3 py-2 general_hover nav__item"
              :class="
                $route.name === 'guida cittadino'
                  ? 'bg-secondary text-white'
                  : ''
              "
            >
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border_3 mobile-nav-tab"
                :class="{
                  'bg-secondary text-white': $route.name === 'guida cittadino',
                }"
                :to="{ name: 'guida cittadino' }"
              >
                <b-icon
                  font-scale="1.3"
                  :icon="
                    this.$route.name === 'guida'
                      ? 'info-circle-fill'
                      : 'info-circle'
                  "
                ></b-icon>
                <span class="mb-0 ml-2">Guida</span>
              </router-link>
            </div>
            <SendHelpRequest />
          </section>
        </div>
      </template>
    </transition-group>
    <b-modal
      id="logout-confirm"
      centered
      title="Confermi di voler uscire dall'area privata?"
      ok-title="Sì, esci"
      ok-variant="popup-button"
      cancel-title="No, annulla"
      cancel-variant="popup-button"
      footer-border-variant="white"
      @ok="onSubmit"
      @cancel="$bvModal.hide('logout-confirm')"
    >
    </b-modal>
  </b-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import NotificationCenter from "@/app/layout/components/NotificationCenter.vue";
import SendHelpRequest from "@/app/common/components/utilities/SendHelpRequest.vue";
import { projectService } from "@/_services";

export default {
  name: "NavigationCittadino",
  props: ["wlistener"],
  components: { NotificationCenter, SendHelpRequest },
  watch: {
    $route(to, from) {
      if (this.wlistener <= 768) {
        this.menuVisible = false;
      }
    },
    wlistener: function (newVal, oldVal) {
      this.menuIsCompact = this.$refs.navmain.clientWidth <= 80;
      if (newVal < 768 && oldVal >= 768) {
        this.menuIsCompact = false;
        this.menuVisible = false;
      }
      if (newVal > 768 && oldVal <= 768) {
        this.menuIsCompact = false;
        this.menuVisible = true;
      }
    },
  },
  computed: {
    welcomeName() {
      if (this.user && this.user.anagrafica && this.user.anagrafica.name) {
        // return (
        //   this.user.anagrafica.name.toLowerCase() +
        //   " " +
        //   this.user.anagrafica.surname.toLowerCase()
        // );
        return this.user.anagrafica.name.toUpperCase();
      }
      return "Profilo";
    },
    filteredActiveServices() {
      // let serviceArray = this.activeServices.filter((service) =>
      //   this.activeRefs.includes(service.position_ref)
      // );

      // if (this.activeRefs.includes(1) || this.activeRefs.includes(2)) {
      //   let serviceSubroutes = [];
      //   if (this.activeRefs.includes(1)) {
      //     serviceSubroutes.push(
      //       {
      //         name: "Visite e Prestazioni",
      //         params: { section: "nuovo", type: "prestazione" },
      //       },
      //       {
      //         name: "Farmaci",
      //         params: { section: "nuovo", type: "prodotto" },
      //       }
      //     );
      //   }
      //   if (this.activeRefs.includes(2)) {
      //     serviceSubroutes.push({
      //       name: "Teleconsulto",
      //       params: { section: "nuovo", type: "teleconsulto" },
      //     });
      //   }
      //   let prenotazioneService = {
      //     name: "Prenotazioni",
      //     logo: "layout-text-sidebar-reverse",
      //     disabled: false,
      //     position_ref: 1,
      //     routeName: "appuntamenti",
      //     scope: undefined,
      //     submenu: true,
      //     submenuVisible: false,
      //     // subroutes: serviceSubroutes,
      //     subroutes: [
      //       {
      //         name: "Visite e Prestazioni",
      //         params: { section: "nuovo", type: "prestazione" },
      //       },
      //       {
      //         name: "Farmaci",
      //         params: { section: "nuovo", type: "prodotto" },
      //       },
      //       {
      //         name: "Teleconsulto",
      //         params: { section: "nuovo", type: "teleconsulto" },
      //       },
      //     ],
      //   };
      //   // serviceArray.splice(0, 0, prenotazioneService);
      //   this.activeServices.splice(0, 0, prenotazioneService);
      // }
      // return serviceArray.filter(
      //   (service) => !this.isBlogService(service) || this.showProjectMenu
      // );
      return this.activeServices.filter(
        (service) => !this.isBlogService(service) || this.showProjectMenu
      );
    },
    ...mapState("utente", ["status", "user"]),
    ...mapState("settings", ["activeRefs", "userSubscriptionNeeds"]),
  },
  data() {
    return {
      avatarPath: null,
      menuVisible: true,
      showCompanyAlert: null,
      activeServices: [
        {
          name: "Prenotazioni",
          logo: "layout-text-sidebar-reverse",
          disabled: false,
          position_ref: 1,
          routeName: "appuntamenti",
          scope: undefined,
          submenu: true,
          submenuVisible: false,
          subroutes: [
            {
              name: "Visite e Prestazioni",
              params: { section: "nuovo", type: "prestazione" },
            },
            {
              name: "Farmaci",
              params: { section: "nuovo", type: "prodotto" },
            },
          ],
        },
        {
          name: "Teleconsulti",
          logo: "chat-left",
          disabled: false,
          position_ref: 2,
          routeName: "teleconsulti",
          scope: undefined,
          submenu: true,
          submenuVisible: false,
          subroutes: [
            {
              name: "Rapido",
              path: "tcemergenza",
              params: { section: "nuovo", subsection: "tcemergenza" },
              position_ref: 10,
            },
            {
              name: "Pediatrico",
              path: "pediatrico",
              params: { section: "nuovo", subsection: "pediatrico" },
              position_ref: 11,
            },
            {
              name: "Specialistico",
              path: "specialistico",
              params: {
                subsection: "specialistico",
                section: "nuovo",
                type: "teleconsulto",
              },
              position_ref: 2,
            },
          ],
        },
        {
          name: "Familiari",
          logo: "people",
          disabled: false,
          position_ref: 4,
          routeName: "familiari",
          scope: undefined,
          submenu: true,
          submenuVisible: false,
          subroutes: [
            {
              name: "Tutti",
              path: "main",
              params: { section: "main" },
            },
            {
              name: "Nuovo",
              path: "nuovo",
              params: { section: "nuovo" },
            },
          ],
        },
        {
          name: "Blog",
          logo: "journal",
          disabled: false,
          position_ref: 9,
          routeName: "progetti cittadino",
          scope: undefined,
          submenu: true,
          submenuVisible: false,
          subroutes: [],
        },
        {
          name: "Profilo Sanitario",
          logo: "journal-medical",
          disabled: false,
          position_ref: 4,
          routeName: "profilo sanitario",
          scope: undefined,
          submenu: false,
          submenuVisible: false,
        },
        {
          name: "Circolo di Cura",
          logo: "bookmark-heart",
          disabled: false,
          position_ref: 1,
          routeName: "circolo di cura",
          scope: undefined,
          submenu: false,
          submenuVisible: false,
          subroutes: [
            {
              name: "Gestione",
              path: "prenotazioni",
              params: { section: "prenotazioni" },
            },
            {
              name: "Strumenti",
              path: "strumenti",
              params: { section: "strumenti" },
            },
            {
              name: "Report",
              path: "report",
              params: { section: "report" },
            },
          ],
        },
      ],
      showProjectMenu: false,
      acquisti: {
        name: "Acquisti",
        logo: "cart",
        disabled: false,
        position_ref: 8,
        routeName: "sottoscrizioni paziente",
        scope: undefined,
        submenu: true,
        submenuVisible: false,
        subroutes: [
          {
            name: "Sottoscrizioni",
            path: "gestione",
          },
          {
            name: "Storico",
            path: "storico",
          },
        ],
      },
      // progetti: {
      //   name: "Vai ai Blog",
      //   logo: "journal",
      //   disabled: false,
      //   position_ref: 9,
      //   routeName: "progetti",
      //   submenu: true,
      //   submenuVisible: false,
      //   subroutes: [
      //     {
      //       name: "Sottoscrizioni",
      //       path: "gestione"
      //     },
      //     {
      //       name: "Storico",
      //       path: "storico"
      //     }
      //   ]
      // },
      agendaSet: {
        name: "Agenda",
        position_ref: 5,
        routeName: "agenda",
        logo: "calendar4",
        disabled: false,
        btnText: "Consulta",
      },
      clientiSet: {
        name: "Pazienti",
        position_ref: 6,
        logo: "journal-medical",
        disabled: false,
        btnText: "Consulta",
      },
      impostazioniSet: {
        name: "Impostazioni",
        routeName: "ImpostazioniCittadinoMain",
        logo: "gear",
        disabled: false,
        submenu: false,
      },
      menuIsCompact: false,
    };
  },
  methods: {
    ...mapActions("utente", ["logout"]),
    ...mapActions("company", ["logUserCompanyIn", "companyLogin"]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    onSubmit() {
      this.logout();
    },
    checkPath() {
      if (this.user) {
        return "/" + this.user.i_am + "/home";
      }
    },
    getProjects() {
      const self = this;
      projectService
        .getOwnProjects()
        .then((response) => {
          self.showProjectMenu = response.data.data.length > 0;

          self.activeServices.find(
            (service) => service.name === "Blog"
          ).subroutes = response.data.data.map((project) => {
            return {
              name: project.name,
              hashid: project.hashid,
            };
          });
        })
        .catch((error) => {
          if (error.status !== 403) {
            self.errorAlert("Non è stato possibile aggiornare il blog");
          }
        });
    },
    isBlogService(service) {
      return service.name.toLowerCase().includes("blog");
    },
    handleMenuSize() {
      var navById = document.getElementsByClassName("nav-desktop")[0];
      var currentWid = navById.clientWidth;
      if (currentWid <= 80) {
        //
        navById.style.minWidth = "inherit";
        navById.style.flex = "0 0 15em";
        this.menuIsCompact = false;
      } else {
        //
        navById.style.minWidth = "0";
        navById.style.flex = "0 0 79px";
        this.menuIsCompact = true;
      }
    },
    redirectRoute(service) {
      if (service.routeName === "profilo sanitario") {
        return {
          name: service.routeName,
          params: {
            section: "rilevazioni",
          },
        };
      } else {
        return { name: service.routeName };
      }
    },
    redirectRouteMobile(service) {
      if (service.routeName === "profilo sanitario") {
        return {
          name: service.routeName,
          params: {
            section: "rilevazioni",
          },
        };
      } else
        return {
          name: service.routeName,
          params: {
            section: service?.subroutes?.[0]?.path ?? service?.routeName,
          },
        };
    },
  },
  created() {
    if (this.$root.isCollapsed) {
      this.menuVisible = false;
    }
    if (this.user.profile_pic) {
      this.avatarPath = this.user.profile_pic;
    }
    this.getProjects();
  },
  mounted() {
    // console.log(this.$refs.navmain.clientWidth);
    this.menuIsCompact = this.$refs.navmain.clientWidth <= 80;
  },
  updated() {},
};
</script>

<style lang="scss" scoped>
.mobile-menu-visible {
  min-height: 100vh;
  max-height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 4;
  transition: all;
}

.mobile-menu {
  position: relative;
  transition: all;
}
</style>
