import config from "../_config/config";
import { authHeader, postHeader } from "../_helpers";
import axios from "axios";

axios.defaults.baseURL = config.apiUrl;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.put["X-Requested-With"] = "XMLHttpRequest";

export const popupService = {
  getUserPopupNotifications,
  markPopupNotificationAsSeen,
};

function getUserPopupNotifications(filters) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("popupnotifications", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function markPopupNotificationAsSeen(hashid, data = {}) {
  const sett = { headers: authHeader() };
  const endpoint = "popupnotifications/" + hashid+"/seen";
  return axios
    .patch(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
