var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.started)?_c('div',[_c('p',{staticClass:"d-flex justify-content-center mb-0"},[_c('b-button',{staticClass:"mx-auto my-2",attrs:{"variant":"secondary font-weight-bold text-center"},on:{"click":_vm.start}},[_vm._v("Ok, Procedi")])],1)]):_c('div',[_c('b-tabs',{attrs:{"align":"center","active-nav-item-class":"text-secondary border-0 d-none","active-tab-class":"text-dark","content-class":"mt-3","nav-class":"border-0 text-white d-none","no-key-nav":""},model:{value:(_vm.questIndex),callback:function ($$v) {_vm.questIndex=$$v},expression:"questIndex"}},[_vm._l((_vm.domande),function(domanda,ind){return [(!domanda.si && !domanda.hi)?[_c('b-tab',{key:ind,attrs:{"title":"","title-item-class":"disabledTab"}},[_c('div',{staticClass:"mx-auto my-4"},[_c('h5',{},[_c('span',{},[_vm._v(_vm._s(_vm.questionIndex(ind))+".")]),_vm._v(" "+_vm._s(domanda.q)+" ")]),_c('br'),_c('b-form-group',{key:'fgrc_' + ind},[(domanda.o && domanda.o === 'file')?_c('div',{staticClass:"align-self-center"},[_c('SurveyUploader',{attrs:{"accepts":domanda.fa,"isMulti":domanda.fm},on:{"upload-result":function($event){return _vm.handleUpload($event, ind)},"remove-result":function($event){return _vm.removeUpload(ind)}}})],1):(domanda.cb && domanda.o)?_c('b-form-checkbox-group',{attrs:{"name":'q_' + ind,"options":domanda.o,"name":"flavour-1"},model:{value:(domanda.a),callback:function ($$v) {_vm.$set(domanda, "a", $$v)},expression:"domanda.a"}}):(domanda.o)?_c('div',_vm._l((domanda.o),function(opzione,indx){return _c('b-button',{key:indx,staticClass:"col-lg-3 col-sm-4 mx-1 my-2 fixed__h text__control",attrs:{"variant":_vm.domande[ind].a === opzione
                        ? 'secondary'
                        : 'outline-secondary'},on:{"click":function($event){return _vm.selectOption(domanda, opzione, ind)}}},[_vm._v(_vm._s(opzione))])}),1):_c('b-form-textarea',{directives:[{name:"capitalize-first",rawName:"v-capitalize-first"}],staticClass:"my-2 col-10 mx-auto",attrs:{"placeholder":"Scrivi qui"},model:{value:(domanda.a),callback:function ($$v) {_vm.$set(domanda, "a", $$v)},expression:"domanda.a"}})],1)],1),_c('b-row',{staticClass:"justify-content-center"},[(ind > 0)?_c('b-button',{staticClass:"mx-2",attrs:{"variant":"secondary"},on:{"click":function($event){_vm.questIndex--}}},[_vm._v("Torna indietro")]):_vm._e(),_c('b-button',{staticClass:"mx-2",attrs:{"variant":"secondary","disabled":domanda.r !== undefined &&
                  domanda.r === true &&
                  (domanda.a === null || domanda.a === '')},on:{"click":function($event){_vm.isLast ? _vm.finalizza() : _vm.next()}}},[_vm._v("Procedi")]),(domanda.r !== undefined && domanda.r === false)?_c('b-button',{staticClass:"mx-2",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.skip(ind)}}},[_vm._v("Salta")]):_vm._e()],1),_c('br')],1)]:(
            domanda.si && _vm.domande[domanda.si.index].a === domanda.si.response
          )?[(
              !domanda.hi ||
              (domanda.hi &&
                _vm.domande[domanda.hi.index].a != domanda.hi.response)
            )?[_c('b-tab',{key:ind,attrs:{"title":"","title-item-class":"disabledTab"}},[_c('div',{staticClass:"mx-auto my-4"},[_c('h5',{},[_c('span',{},[_vm._v(_vm._s(_vm.questionIndex(ind))+".")]),_vm._v(" "+_vm._s(domanda.q)+" ")]),_c('br'),_c('b-form-group',{key:'fgrc_' + ind},[(domanda.o && domanda.o === 'file')?_c('div',{staticClass:"align-self-center"},[_c('SurveyUploader',{attrs:{"accepts":domanda.fa,"isMulti":domanda.fm},on:{"upload-result":function($event){return _vm.handleUpload($event, ind)},"remove-result":function($event){return _vm.removeUpload(ind)}}})],1):(domanda.cb && domanda.o)?_c('b-form-checkbox-group',{attrs:{"name":'q_' + ind,"options":domanda.o,"name":"flavour-1"},model:{value:(domanda.a),callback:function ($$v) {_vm.$set(domanda, "a", $$v)},expression:"domanda.a"}}):(domanda.o)?_c('div',[(domanda.o.length <= 9)?_vm._l((domanda.o),function(opzione,indx){return _c('b-button',{key:indx,staticClass:"col-lg-3 col-sm-4 mx-1 my-2 fixed__h text__control",attrs:{"variant":_vm.domande[ind].a === opzione
                            ? 'secondary'
                            : 'outline-secondary'},on:{"click":function($event){return _vm.selectOption(domanda, opzione, ind)}}},[_vm._v(_vm._s(opzione))])}):[_c('b-form-select',{directives:[{name:"capitalize-first-select",rawName:"v-capitalize-first-select"}],staticClass:"col-lg-10 col-12",attrs:{"name":'qc_' + ind,"required":domanda.r !== undefined ? domanda.r : true},model:{value:(domanda.a),callback:function ($$v) {_vm.$set(domanda, "a", $$v)},expression:"domanda.a"}},_vm._l((domanda.o),function(opt){return _c('b-form-select-option',{key:opt,attrs:{"value":opt}},[_vm._v(_vm._s(opt)+" ")])}),1)]],2):_c('b-form-textarea',{directives:[{name:"capitalize-first",rawName:"v-capitalize-first"}],staticClass:"my-2 col-10 mx-auto",attrs:{"placeholder":"Scrivi qui"},model:{value:(domanda.a),callback:function ($$v) {_vm.$set(domanda, "a", $$v)},expression:"domanda.a"}})],1)],1),_c('b-row',{staticClass:"justify-content-center"},[_c('b-button',{staticClass:"mx-2",attrs:{"variant":"secondary"},on:{"click":function($event){_vm.questIndex--}}},[_vm._v("Torna indietro")]),_c('b-button',{staticClass:"mx-2",attrs:{"variant":"secondary"},on:{"click":_vm.next}},[_vm._v("Procedi")]),(domanda.r !== undefined && domanda.r === false)?_c('b-button',{staticClass:"mx-2",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.skip(ind)}}},[_vm._v("Salta")]):_vm._e()],1),_c('br')],1)]:_vm._e()]:_vm._e()]})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }