<template>
  <div v-if="response" class="my-2">
    <span class="secondary"
      >File {{ isMulti ? "caricati" : "caricato" }} correttamente!</span
    >
    <span class="text-danger point ml-1" @click="refreshAll">Rimuovi </span>
  </div>
  <b-form
    @submit.prevent="doUpload"
    class="d-flex align-content-center justify-content-between flex-wrap my-2"
    v-else
  >
    <b-form-file
      v-if="!isMulti"
      @change="handleInputChange"
      v-model="form.file"
      :accept="acceptedFormat"
      required
      ref="file-up"
      placeholder="seleziona file..."
      class="text-secondary custom-file-input my-1 border-transparent col-12 col-md-9 mt-2"
    ></b-form-file>
    <b-form-file
      v-else
      @change="handleInputChange"
      v-model="form.files"
      :accept="acceptedFormat"
      multiple
      required
      ref="file-up"
      placeholder="seleziona uno o più file..."
      class="text-secondary custom-file-input my-1 border-transparent col-12 col-md-9 mt-2"
    ></b-form-file>
    <b-button
      type="submit"
      class="col-12 col-md-3 mt-2"
      size="sm"
      variant="secondary  text-white my-1"
    >
      <b-spinner v-if="uploading"></b-spinner>
      <span v-else> Carica </span>
    </b-button>
    <!-- <b-button @click="doUpload" :disabled="form.file == null && multiIsEmpty" class="col-12 mt-2 col-lg-3"
      size="sm" variant="secondary  text-white   my-1">
      Carica</b-button> -->
  </b-form>
</template>
<script>
import { reservationService } from "@/_services";
export default {
  name: "SurveyUploader",
  props: ["dest", "accepts", "isMulti"],
  data() {
    return {
      form: {
        file: null,
        files: null,
        destination: "order",
        name: null,
        has_owner: false,
        encrypt: true,
      },
      response: null,
      acceptedFormat: "image/*",
      multipleUps: false,
      upError: false,
      upMessage: null,
      uploading: false,
    };
  },
  computed: {
    multiIsEmpty: function () {
      if (this.form.files !== null) {
        return this.form.files.length == 0;
      }
      return this.form.files === null;
    },
  },
  methods: {
    doUpload() {
      this.uploading = true;
      var form = new FormData();
      if (!this.isMulti) {
        form.append("file", this.form.file);
        form.append("name", this.form.file.name);
      } else {
        // this.form.files.forEach((file, kk) => {
        //   form.append('files' + kk, file);
        // });
        this.form.files.forEach((v) => form.append(`${"files"}[]`, v));
      }
      form.append("destination", this.form.destination);
      form.append("has_owner", this.form.has_owner);
      form.append("encrypt", this.form.encrypt);
      var self = this;
      reservationService
        .uploadFile(form)
        .then(function (res) {
          // console.log(res);
          self.response = res.data.data;
          self.$emit("upload-result", res.data.data);
          self.$emit("input-state", false);
          self.uploading = false;
        })
        .catch(function (err) {
          console.log(err);
          if (err.data.errors && err.data.errors.file) {
            self.upMessage =
              err.data.errors.file[err.data.errors.file.length - 1];
          }
          self.upError = true;
          self.uploading = false;
        });
    },
    refreshAll() {
      this.form.file = null;
      this.form.destination = "order";
      if (this.dest) {
        this.form.destination = this.dest;
      }
      this.form.name = null;
      this.form.has_owner = false;
      this.form.encrypt = true;
      this.$emit("remove-result", this.response);
      this.response = null;
    },
    handleInputChange(ev) {
      if (this.isMulti) {
        var isInputNotEmpty = ev.target.value ? true : false;
      } else {
        var isInputNotEmpty = this.form.file == null;
      }
      this.$emit("input-state", isInputNotEmpty);
    },
  },
  created() {
    if (this.dest) {
      this.form.destination = this.dest;
    }
    if (this.accepts) {
      this.acceptedFormat = this.accepts;
    }
    if (this.isMulti) {
      this.form.files = [];
    }
    // console.log(this.form.destination);
  },
};
</script>
