<template>
  <div id="app" class="d-flex flex-column">
    <b-alert
      :variant="alert.type"
      dismissible
      fade
      :show="alert.message !== null"
      @dismissed="clearAlert"
      @dismiss-count-down="clearAlert"
      class="col-12 mr-0 ml-auto fixed__alert"
    >
      {{ alert.message }}
    </b-alert>
    <!-- MAIN -->
    <!-- <b-container fluid class="p-0 custom__content__df main__operatore"> -->
    <b-container fluid class="p-0 custom__content__df">
      <b-row cols="1">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </b-row>
    </b-container>
    <!-- <b-row v-if="!user">
      <CallMailSupportButtons></CallMailSupportButtons>
    </b-row> -->

    <!-- footer -->
    <!-- <b-button @click="setCssPuntofarma">CAMBIA TEMA COLORE</b-button> -->
    <div class="py-0 px-0 mt-auto pb-1">
      <div class="px-0 text-center fs-6 bg-mdm-light-grey shadow_1">
        <!-- :class="showScrollBtn ? 'col-lg-9' : 'col-lg-12'" -->
        <span class="mx-1 line__h">
          Copyright © - Qwince s.r.l. - Via Principe di Belmonte, 102 - 90139 Palermo - Partita
          IVA: 11801100964
        </span>
        <span>- </span>
        <template v-if="user && user.type === 1">
          <a :href="termsConditionLinks.operatore" target="__blank"
            >Termini e condizioni
          </a>
          <span class="mx-0 px-0"> - </span>
          <a
            :href="privacyPolicyLinks.operatore"
            target="__blank"
            class="mx-0 px-0"
          >
            Informativa Privacy
          </a>
        </template>
        <template v-else>
          <a :href="termsConditionLinks.paziente" target="__blank"
            >Termini e condizioni
          </a>
          <span class="mx-0 px-0"> - </span>
          <a
            :href="privacyPolicyLinks.paziente"
            target="__blank"
            class="mx-0 px-0"
          >
            Informativa Privacy
          </a>
        </template>
        <span class="mx-0 px-0"> - </span>
        <a class="point" @click="$bvModal.show('about-modal')" to="">About</a>
        <span class="mx-0 px-0"> - </span>
        <ChangeLogComponent />
      </div>
    </div>
    <b-modal
      id="about-modal"
      centered
      hide-footer
      content-class="about-modal-content"
      header-class="pb-0 pt-2"
      body-class="pt-1"
    >
      <template #default>
        <div class="about-image-container">
          <img
            class="point about-modal-image"
            @click="
              $bvModal.hide('about-modal');
              $router.push({ name: 'help' });
            "
            :src="require(`@/assets/${$root.scope}/etichetta.png`)"
            alt="About
          image"
          />
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions } from "vuex";
import ChangeLogComponent from "@/app/layout/components/ChangeLogComponent.vue";
// import CallMailSupportButtons from "@/app/layout/components/CallMailSupportButtons.vue";

export default {
  components: {
    ChangeLogComponent,
    // CallMailSupportButtons,
  },
  data() {
    return {
      showScrollBtn: false,
      themeCustomOption: false,
      currentPfVersion: process.env.VUE_APP_PF_VERSION,
      termsConditionLinks: {
        paziente: process.env.VUE_APP_LINK_TC_PAZIENTE,
        operatore: process.env.VUE_APP_LINK_TC_OPERATORE,
      },
      privacyPolicyLinks: {
        paziente: process.env.VUE_APP_LINK_TC_PAZIENTE,
        operatore: process.env.VUE_APP_LINK_INFORMATIVA_OPERATORE,
      },
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
    }),
    ...mapActions("utente", ["me"]),
    ...mapActions("company", ["logUserCompanyIn"]),
    // ...mapActions("settings", ["setActiveServices"]),
    scrollUp() {
      window.scrollTo(0, 0);
    },
    showScrollUp() {
      var bottom = document.querySelector("#page__bottom");
      if (!bottom) {
        return false;
      }
      var bounding = bottom.getBoundingClientRect();
      if (
        bounding.bottom >
        (window.innerHeight || document.documentElement.clientHeight)
      ) {
        return true;
      }
      return false;
    },
    setCss() {
      //  var  ref=  `url(${require("@/assets/compiled/app.custom.css")})`
      var theme = this.themeCustomOption ? "custom" : "puntofarma";
      this.themeCustomOption = !this.themeCustomOption;
      var link = document.createElement("link");
      link.rel = "stylesheet";
      link.id = "customstyle";
      link.type = "text/css";
      // link.href = `url(${require("@/assets/compiled/app.custom.css")})`;
      link.href = "/compiled/app." + theme + ".css";
      document.head.appendChild(link);
      // console.log(document.head);
    },
    setCssPuntofarma() {
      var scriptel = document.getElementById("customstyle");
      try {
        scriptel.remove();
      } catch (err) {
        // console.log(err)
        return;
      }
      this.setCss();
    },
  },
  watch: {
    $route(to, from) {
      // clear alert on location change
      this.clearAlert();
      this.showScrollBtn = this.showScrollUp();
      // console.log(to);
    },
  },
  created() {
    // this.setCss();

    if (this.user) {
      this.me();
      if (this.user.type === 1) {
        this.logUserCompanyIn();
      }
    }
    const mainPages = [
      "/cittadino/home",
      "/operatore/home",
      "/admin/home",
      "/impostazioni",
      "/profilo",
      "/orari",
    ];
    // console.log(this.$route);
    if (!mainPages.includes(this.$route.path)) {
      this.$root.menuLeft = true;
    }
  },
  mounted() {
    // this.setActiveServices();
  },
  updated() {
    // console.log("APP HAS UPDATED", this.$root.isMobile);
    this.showScrollBtn = this.showScrollUp();
  },
};
</script>

<style lang="scss" scoped>
#logo-column {
  width: 120px;
  background-color: blue;
}

#card-wrapper {
  border: 0.5px solid rgb(209, 209, 209);
  border-radius: 10px;
}

#qr-code-row {
  width: 120px;
  background-color: red;
}

.indxz {
  z-index: 30;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-height: 100vh;
}

#page__bottom {
  background-color: darkgray;
  min-height: 100%;
}

#lower-iso-card {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 550;
  z-index: 100;
  position: relative;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  margin-top: -5px;
}

.custom__content__df {
  min-height: 70vh;
}

.line__h {
  line-height: 2.5;
}

.fixed__alert {
  position: fixed !important;
  z-index: 9999999 !important;
}

#version-data-block {
  font-size: 13px;
}

#etichetta-top-section {
  display: flex;
  border-bottom: 0.5px solid rgb(209, 209, 209);
}

.about-modal-content {
  padding: 0;
}

.about-image-container {
  max-width: 466px;
  max-height: 366px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.about-modal-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
</style>
