<template>
  <div class="text-center col-10 col-lg-8 col-xl-7 mx-auto p-4">
    <b-row col="1" class="col-12 text-center">
      <b-col class="mx-auto col-10 col-xl-8">
        <b-img
          :src="require(`@/assets/${$root.scope}/logo.svg`)"
          fluid-grow
          alt="logo app"
        ></b-img>
      </b-col>
    </b-row>
    <div v-if="logged && utente" class="p-4 text-center">
      <GuidaOperatore v-if="utente.type === 1"> </GuidaOperatore>
      <GuidaCittadino v-else> </GuidaCittadino>
    </div>
    <div v-else>
      <GuidaCittadino> </GuidaCittadino>
    </div>
    <b-col class="py-4">
      <b-button @click="$router.back()">Indietro</b-button>
    </b-col>
  </div>
</template>

<script>
import GuidaOperatore from "@/app/common/components/GuidaOperatore.vue";
import GuidaCittadino from "@/app/common/components/GuidaCittadino.vue";
export default {
  name: "Help",
  components: { GuidaOperatore, GuidaCittadino },
  data() {
    const loggedIn = localStorage.getItem("user");
    const user = JSON.parse(localStorage.getItem("user"));
    return {
      logged: loggedIn,
      utente: user,
    };
  },
};
</script>
