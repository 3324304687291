export const SERVIZI_VARIANTS = {
  prodotti: "primary",
  prodotto: "primary",
  prestazioni: "blue",
  prestazione: "blue",
  teleconsulti: "secondary",
  teleconsulto: "secondary",
};

export const CANCELLATION_MESSAGES = {
  1: "Ci spiace comunicarLe che non possiamo gestire ulteriori richieste per la data selezionata. La preghiamo di effettuare una nuova prenotazione per la settimana successiva alla data scelta.",
  2: "Ci spiace comunicarLe che a causa di problematiche relative all'approvvigionamento non possiamo gestire la sua richiesta per la data selezionata.",
  3: "Ci spiace comunicarLe che non possiamo gestire la sua richiesta per mancanza di dati essenziali. La preghiamo di completare la sua scheda anagrafica.",
  4: "Si è verificato un imprevisto che ci costringe a disdire il suo appuntamento. Se lo desidera, ci chiami per ulteriori informazioni.",
};

export const CANCELLATION_MESSAGES_CITTADINO = {
  1: "Non sono più interessato.",
  2: "Non posso rispettare l'impegno.",
};

export const STATUS_VARIANTS = {
  confermato: "secondary",
  ricevuto: "primary",
  annullato: "danger",
  rifiutato: "danger",
  "in attesa di risposta": "warning",
  evaso: "primary-darker",
};

export const MONTHS = [
  { label: "Gennaio", value: 1 },
  { label: "Febbraio", value: 2 },
  { label: "Marzo", value: 3 },
  { label: "Aprile", value: 4 },
  { label: "Maggio", value: 5 },
  { label: "Giugno", value: 6 },
  { label: "Luglio", value: 7 },
  { label: "Agosto", value: 8 },
  { label: "Settembre", value: 9 },
  { label: "Ottobre", value: 10 },
  { label: "Novembre", value: 11 },
  { label: "Dicembre", value: 12 },
];

export const FREQUENCYMAP = {
  0: { singular: "mese", plural: "mesi" },
  1: { singular: "settimana", plural: "settimane" },
  2: { singular: "giorno", plural: "giorni" },
  3: { singular: "ora", plural: "ore" },
  4: { singular: "minuto", plural: "minuti" },
};

export const NEXI_ESITO_MAP = {
  OK: "Transazione completata correttamente",
  KO: "transazione fallita",
  ANNULLO: "transazione annullata",
  ERRORE: "errore transazione",
  PEN: "transazione pendente",
};

export const NEXI_MESSAGE_MAP = {
  "Message OK": "Transazione autorizzata.",
  "Controllo CF": "Il PAN della carta è già associato ad altro codice Fiscale.",
  "Controllo PAN":
    "Al codice Fiscale indicato sono già associate un numero massimo (numero accordato con Nexi) di carta.",
  "Controllo BLACKLIST":
    "Transazione bloccata per applicazione regole blacklist se previsto dal profilo esercente.",
  "Controllo CF/PAN":
    "Errore sul controllo tra Codice Fiscale e PAN, ad esempio il controllo è attivo e non viene passato dal merchant il codice Fiscale.",
  "Auth. Denied": "Transazione non autorizzata.",
  "Impossibile eseguire la Post di Notifica":
    "Transazione bloccata se il profilo dell’esercente prevede l’annullamento della transazione in caso di notifica server to server verso l’urlpost fallita.",
  "3D Secure annullato da utente":
    "Autenticazione 3D-Secure non completata correttamente o annullata dall’utente.",
  "Carta non autorizzata causa applicazione regole BIN table":
    "Transazione bloccata se non superato il controllo BIN table abilitato sul profilo esercente.",
  "Problema 3DSecure":
    "Impossibile completare la transazione per problemi sul 3D-Secure, ad esempio l’utente non rientra dalla fase di autenticazione o problemi sull’attivazione del profilo esercente al servizio.",
  "expired card":
    "Codice Esercente acquirer non correttamente abilitato o revocato.",
  "Invalid merchant":
    "Codice Esercente acquirer non correttamente abilitato o revocato.",
  "transaction not permitted": "Transazione non permessa.",
  "not sufficient funds":
    "Transazione negata per mancata disponibilità di fondi sulla carta per l’importo richiesto.",
  "Technical problem": "Problema tecnico sui sistemi autorizzativi.",
  "Host not found": "Sistema autorizzativo issuer non disponibile.",
  "Transazione chiusa per time-out":
    "La transazione si è conclusa dopo il time-out settato sul profilo dell’esercente.",
  "Controllo PAN/CONTRATTO":
    "Transazione bloccata per applicazione regola check su verifica presenza PAN su altro num_contratto se previsto dal profilo esercente.",
  "Numero di tentativi di retry esaurito":
    "Superato il numero (il numero è definito a livello di profilo esercente da 1 a 3) di tentativi ko sul medesimo codTrans.",
  "soft decline":
    "Transazione rifiutata dall’emittente dalla carta perché richiede l’autenticazione forte(SCA del cliente, ritentare il pagamento.",
  "Transazione avvenuta correttamente":
    "La transazione è stata completata con esito positivo. Questo messaggio viene restituito per i metodi di pagamento alternativi.",
};

export const NEXI_STATO_MAP = {
  0: "creato",
  1: "inizializzato",
  "-1": "errore inizializzazione",
  2: "finalizzato",
  "-2": "errore finalizzazione",
  3: "rimborsato",
  "-3": "errore rimborso",
  4: "annullato",
  "-4": "errore annullamento",
};

export const CITTADINO_ROUTE_NAMES = {
  // CittadinoHome: "",
  familiari: "I tuoi familiari",
  // appuntamenti: "Visite e Prestazioni",
  // appuntamentiFarmaci:"Farmaci",
  // appuntamentiTeleconsulti:"Teleconsulti",
  appuntamenti:{
    prestazione:"Visite e Prestazioni",
    prodotto:"Farmaci",
    // teleconsulto:"Teleconsulti",
  },
  agenda: "La tua agenda",
  "il tuo consulto": "Il tuo consulto",
  "circolo di cura": "Il tuo circolo di cura",
  "sottoscrizioni paziente": "Acquisti e Sottoscrizioni",
  "progetti cittadino": "Blog",
  "profilo sanitario": "Profilo sanitario",
  ImpostazioniCittadinoMain: "Impostazioni",
  "profilo cittadino": "Dati Anagrafici",
  "guida cittadino": "Guida",
  "completa questionario": "Completa il questionario",
  "score questionario": "Completa il questionario",
  "nuova terapia assistito": "Nuova terapia assistito",
  PaymentOutcome: "Acquisto",
  "pagina non trovata": "pagina non trovata",
  NumberVerification: "Verifica il tuo numero",
  EmailVerification: "Verifica la tua email",
  // "teleconsulti":"Teleconsulti",
  teleconsulti:{
    tcemergenza:"Teleconsulto Rapido",
    pediatrico:"Teleconsulto Pediatrico",
    specialistico:"Teleconsulto Specialistico",
    // teleconsulto:"Teleconsulti",
  },
};

export const OPERATORE_ROUTE_NAMES = {
"agenda appuntamenti": "Calendario",
pazienti: "Pazienti",
prenotazioni:"Prenotazioni",
teleconsulto:"Teleconsulto",
attivita:"Screening",
"profilo operatore":"Profilo",
impostazioniOperatore:"Impostazioni",
guida:"Guida",
"scheda paziente":"Scheda paziente",
"prenota servizio":"Scheda paziente",
"centri erogatori":"Gestione centri erogatori",
// "dettaglio centro erogatore":"Gestione centri erogatori",
"nuova prenotazione paziente":"Nuova prenotazione paziente",
emergenze:"Richieste di Emergenza",
"dettaglio piano terapeutico":"Piani Terapeutici",
"appuntamentiASL":"Appuntamenti in ASL",
"progetti operatore":"Blog",
archivio: "Archivio File",
};

export const HFA_ICOS={
  1:"Rischio basso",
  2:"Rischio moderato",
  3:"Rischio alto",
  4:"Rischio molto alto",
}