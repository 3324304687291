import Vue from "vue";
import Vuex from "vuex";

import { utente } from "@/_store/user.module";
import { alert } from '@/_store/alert.module';
import { utility } from '@/_store/utility.module';
import { company } from '@/_store/company.module';
import { modal } from '@/_store/modal.module';
import { settings } from '@/_store/settings.module';
import { tcsurvey } from '@/_store/tcsurvey.module';

Vue.use(Vuex);

export default new Vuex.Store({
  // state: {},
  // mutations: {},
  // actions: {},
  modules: {
    utente,
    alert,
    utility,
    company,
    modal,
    settings,
    tcsurvey
    },
});
