<template>
  <div class="w-sm-100 d-md-flex">
    <section class="nav-desktop" style="z-index: 5">
      <NavigationAdmin :wlistener="innerWidth" />
    </section>
    <!-- <b-container class="bg-extra-light pt-4 px-4 ml-1"> -->
    <div class="bg-extra-light pt-4 px-4 ml-1 w-100">
      <router-view></router-view>
    </div>
    <!-- </b-container> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import NavigationAdmin from "@/app/layout/components/NavigationAdmin.vue";

export default {
  name: "AdminMain",
  data() {
    return {
      innerWidth: window.innerWidth,
    };
  },
  components: { NavigationAdmin },
  methods: {
    innerWidthChanged() {
      // console.log("resize handler");
      this.innerWidth = window.innerWidth;
    },
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  created() {
    if (this.user.type !== 2) {
      this.$router.push({ name: "Home" });
    }
  },
};
</script>
