import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/app/common/views/Home.vue";
import LoginPage from "@/app/common/views/LoginPage.vue";
import RegisterPage from "@/app/common/views/RegisterPage.vue";
import EmailVerification from "@/app/common/views/EmailVerification.vue";
import NumberVerification from "@/app/common/views/NumberVerification.vue";
import RecoverPassword from "@/app/common/views/RecoverPassword.vue";
import PasswordRecovery from "@/app/common/views/PasswordRecovery.vue";
import Profilo from "@/app/common/views/Profilo.vue";
import Disabled from "@/app/common/views/Disabled.vue";
import NotFoundPage from "@/app/common/views/NotFoundPage.vue";
import Help from "@/app/common/views/Help.vue";
import CittadinoMain from "@/app/layout/views/CittadinoMain.vue";
import OperatoreMain from "@/app/layout/views/OperatoreMain.vue";
import AdminMain from "@/app/layout/views/AdminMain.vue";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    // component: () => import('@/views/Home.vue'),
  },
  { path: "/login", name: "LoginPage", component: LoginPage },
  { path: "/register", name: "RegisterPage", component: RegisterPage },
  { path: "/disabled", name: "Disabled", component: Disabled },
  {
    path: "/password/reset",
    name: "RecoverPassword",
    component: RecoverPassword,
  },
  {
    path: "/help",
    name: "help",
    component: Help,
  },
  {
    path: "/password/recovery",
    name: "recupero password",
    component: PasswordRecovery,
  },
  {
    path: "/email/verify/:status",
    name: "EmailVerification",
    component: EmailVerification,
  },
  {
    path: "/number/verify",
    name: "NumberVerification",
    component: NumberVerification,
  },
  {
    path: "/payment/:purchase/:outcome?",
    name: "PaymentOutcome",
    component: () => import("@/app/common/views/PaymentOutcome.vue"),
  },
  {
    path: "/cittadino",
    component: CittadinoMain,
    children: [
      {
        path: "home",
        name: "CittadinoHome",
        component: () =>
          import("@/app/modules/homepage/views/CittadinoHome.vue"),
      },
      {
        path: "familiari/:section/:hash?",
        name: "familiari",
        component: () =>
          import("@/app/modules/familiari/views/FamiliariMain.vue"),
      },
      {
        path: "appuntamenti/:section/:type",
        name: "appuntamenti",
        component: () =>
          import(
            "@/app/modules/servizi/prenotazioni/views/PrenotazioniCittadinoMain.vue"
          ),
      },
      {
        path: "agenda",
        name: "agenda",
        component: () =>
          import(
            "@/app/modules/servizi/prenotazioni/views/CittadinoAgenda.vue"
          ),
      },
      {
        path: "teleconsulti/:section/:subsection?/:type?",
        name: "teleconsulti",
        component: () =>
          import(
            "@/app/modules/servizi/teleconsulto/views/TeleconsultoCittadinoMain.vue"
          ),
      },
      // {
      //   path: "teleconsulto",
      //   name: "il tuo consulto",
      //   component: () =>
      //     import(
      //       "@/app/modules/servizi/teleconsulto/views/PreviewConsultoCittadino.vue"
      //     ),
      // },
      {
        path: "circolocura",
        name: "circolo di cura",
        component: () =>
          import("@/app/modules/circoloDiCura/views/CircoloCura.vue"),
      },
      {
        path: "sottoscrizioni/:section/:action?",
        name: "sottoscrizioni paziente",
        component: () =>
          import(
            "@/app/modules/sottoscrizioni/views/SottoscrizioniCittadinoMain.vue"
          ),
      },
      {
        path: "progetti/:hashprogetto/:section/:subsection?",
        name: "progetti cittadino",
        component: () =>
          import("@/app/modules/servizi/blog/views/ProgettiMain.vue"),
      },
      {
        path: "profilosanitario/:section/:action?",
        name: "profilo sanitario",
        component: () =>
          import(
            "@/app/modules/servizi/profiloSanitario/views/ProfiloSanitarioMain.vue"
          ),
      },
      {
        path: "impostazioni",
        name: "ImpostazioniCittadinoMain",
        component: () =>
          import(
            "@/app/modules/impostazioni/views/ImpostazioniCittadinoMain.vue"
          ),
      },
      { path: "profilo", name: "profilo cittadino", component: Profilo },
      {
        path: "/guidacittadino",
        name: "guida cittadino",
        component: () => import("@/app/common/components/GuidaCittadino.vue"),
      },
      {
        path: "questionario/compila/:quest?",
        name: "completa questionario",
        component: () =>
          import(
            "@/app/modules/servizi/screening/views/CompilaQuestionarioCittadino.vue"
          ),
      },
      {
        path: "questionario/score/compila/:quest?",
        name: "score questionario",
        component: () =>
          import(
            "@/app/modules/servizi/screening/views/FillScoreSurveyCittadino.vue"
          ),
      },
      {
        path: "familiare/assistito/terapia",
        name: "nuova terapia assistito",
        component: () =>
          import("@/app/modules/familiari/views/NuovaTerapiaAssistito.vue"),
      },
      {
        path: "profilosanitario/pt/:hash",
        name: "il tuo piano terapeutico",
        component: () =>
          import(
            "@/app/modules/servizi/pianiTerapeutici/views/PianoTerapeuticoCittadino.vue"
          ),
      },
    ],
  },
  {
    path: "/operatore",
    component: OperatoreMain,
    children: [
      {
        path: "home",
        name: "CompanyHome",
        component: () => import("@/app/modules/homepage/views/CompanyHome.vue"),
      },
      {
        path: "analitiche",
        name: "analitiche",
        component: () =>
          import("@/app/modules/statistiche/views/StatisticheCompanyMain.vue"),
      },
      {
        path: "agenda",
        name: "agenda appuntamenti",
        component: () =>
          import("@/app/modules/servizi/prenotazioni/views/CompanyAgenda.vue"),
      },
      {
        path: "pazienti",
        name: "pazienti",
        component: () =>
          import(
            "@/app/modules/servizi/gestionePazienti/views/PazientiOperatoreMain.vue"
          ),
      },
      { path: "profilo", name: "profilo operatore", component: Profilo },
      {
        path: "/guidaoperatore",
        name: "guida",
        component: () => import("@/app/common/components/GuidaOperatore.vue"),
      },
      {
        path: "ricerca/:section?",
        name: "ricerca prestazioni",
        component: () =>
          import(
            "@/app/modules/ricercaPrestazioni/views/RicercaPrestazioniMain.vue"
          )
      },
      {
        path: "centriErogatori/:section?/:hashid?",
        name: "centri erogatori",
        component: () =>
          import("@/app/modules/centriErogatori/views/CentriErogatoriMain.vue"),
      },
      {
        path: "teleconsulto/:section",
        name: "teleconsulto",
        component: () =>
          import(
            "@/app/modules/servizi/teleconsulto/views/TeleconsultoOperatoreMain.vue"
          ),
      },
      {
        path: "attivita/:section/:action?/:quest?",
        name: "attivita",
        component: () =>
          import("@/app/modules/servizi/screening/views/AttivitaMain.vue"),
      },
      {
        path: "conferma-annullamento",
        name: "conferma annullamento",
        component: () =>
          import(
            "@/app/modules/servizi/prenotazioni/views/ConfermaAnnullamento.vue"
          ),
      },
      {
        path: "prenotazioni/:section",
        name: "prenotazioni",
        component: () =>
          import(
            "@/app/modules/servizi/prenotazioni/views/PrenotazioniOperatoreMain.vue"
          ),
      },
      {
        path: "appuntamenti/:section",
        name: "appuntamentiASL",
        component: () =>
          import(
            "@/app/modules/servizi/appuntamenti/views/AppuntamentiMain.vue"
          ),
      },
      {
        path: "progetti/:hashprogetto/:section/:subsection?",
        name: "progetti operatore",
        component: () =>
          import("@/app/modules/servizi/blog/views/ProgettiMain.vue"),
      },
      {
        path: "impostazioni/:section",
        name: "impostazioniOperatore",
        component: () =>
          import(
            "@/app/modules/impostazioni/views/ImpostazioniCompanyMain.vue"
          ),
      },
      {
        path: "bancadati",
        name: "bancadati",
        component: () => import("@/app/modules/libreria/views/BancaDati.vue"),
      },
      {
        path: "archivio",
        name: "archivio",
        component: () => import("@/app/modules/archives/views/CompanyArchiveMain.vue"),
      },
      {
        path: "pazienti/scheda/:hash",
        name: "scheda paziente",
        component: () =>
          import(
            "@/app/modules/servizi/gestionePazienti/views/SchedaPaziente.vue"
          ),
      },
      {
        path: "pazienti/profilo/:hash",
        name: "profilo paziente",
        component: () =>
          import(
            "@/app/modules/servizi/gestionePazienti/views/ProfiloAnagraficoPaziente.vue"
          ),
      },
      {
        path: "pazienti/terapia",
        name: "nuova terapia Paziente",
        component: () =>
          import(
            "@/app/modules/servizi/gestionePazienti/views/NuovaTerapiaPaziente.vue"
          ),
      },
      {
        path: "pazienti/:hash/prenotazioni/nuovo/:tipo",
        name: "nuova prenotazione paziente",
        component: () =>
          import(
            "@/app/modules/servizi/prenotazioni/views/NuovaPrenotazionePaziente.vue"
          ),
      },
      {
        path: "pazienti/servizi/:hash",
        name: "prenota servizio",
        component: () =>
          import(
            "@/app/modules/servizi/prenotazioni/views/OldNuovaPrenotazionePaziente.vue"
          ),
      },
      {
        path: "pazienti/ordine/:hash",
        name: "prenota ordine",
        component: () =>
          import(
            "@/app/modules/servizi/prenotazioni/views/NuovoOrdinePaziente.vue"
          ),
      },
      {
        path: "pazienti/alerts/:hash",
        name: "nuova notifica paziente",
        component: () =>
          import(
            "@/app/modules/servizi/gestionePazienti/views/NuovoAlertPaziente.vue"
          ),
      },
      {
        path: "pazienti/alerts/edit/:hash",
        name: "modifica piano notifiche",
        component: () =>
          import(
            "@/app/modules/servizi/gestionePazienti/views/ModificaAlertPaziente.vue"
          ),
      },
      {
        path: "pazienti/recall/edit/:hash",
        name: "modifica piano recall",
        component: () =>
          import(
            "@/app/modules/servizi/gestionePazienti/views/ModificaAlertPrestazionePaziente.vue"
          ),
      },
      {
        path: "pazienti/alerts/copy/:hash",
        name: "replica piano notifiche",
        component: () =>
          import(
            "@/app/modules/servizi/gestionePazienti/views/ReplicaAlertPaziente.vue"
          ),
      },
      {
        path: "pazienti/referti/dimissioni",
        name: "dimissioni paziente",
        component: () =>
          import(
            "@/app/modules/servizi/gestionePazienti/views/RefertoDimissioni.vue"
          ),
      },
      {
        path: "pazienti/pt/scheda/:hash",
        name: "dettaglio piano terapeutico",
        component: () =>
          import(
            "@/app/modules/servizi/pianiTerapeutici/views/PianoTerapeuticoDetailOperatore.vue"
          ),
      },
      {
        path: "controlroom/:section?/:hash?",
        name: "control room",
        component: () =>
          import(
            "@/app/modules/servizi/monitoraggio/views/MonitoraggioMain.vue"
          ),
      },
      // {
      //   path: "emergenze",
      //   name: "emergenze",
      //   component: () =>
      //     import(
      //       "@/app/modules/servizi/teleconsultoEmergenza/views/EmergenzeOperatoreMain.vue"
      //     ),
      // },
      {
        path: "pt",
        name: "piani terapeutici",
        component: () =>
          import(
            "@/app/modules/servizi/pianiTerapeutici/views/PianiTerapeuticiOperatoreMain.vue"
          ),
      },
      {
        path: "pt/scheda/:hash",
        name: "piano terapeutico",
        component: () =>
          import(
            "@/app/modules/servizi/pianiTerapeutici/views/PianoTerapeuticoDetailOperatoreAsl.vue"
          ),
      },
    ],
  },
  {
    path: "/admin",
    component: AdminMain,
    children: [
      {
        path: "home",
        name: "AdminDashboard",
        component: () => import("@/app/modules/admin/views/AdminDashboard.vue"),
      },
      {
        path: "utenti",
        name: "gestione utenti",
        component: () => import("@/app/modules/admin/views/GestioneUtenti.vue"),
      },
      {
        path: "prenotazioni",
        name: "gestione prenotazioni",
        component: () =>
          import("@/app/modules/admin/views/GestionePrenotazioni.vue"),
      },
      {
        path: "aziende",
        name: "gestione aziende",
        component: () =>
          import("@/app/modules/admin/views/GestioneAziende.vue"),
      },
      {
        path: "aziende/nuova",
        name: "nuova azienda",
        component: () => import("@/app/modules/admin/views/NuovaCompany.vue"),
      },
      {
        path: "aziende/dettaglio/:company",
        name: "dettaglio azienda",
        component: () =>
          import("@/app/modules/admin/views/DettaglioAzienda.vue"),
      },
      {
        path: "servizi",
        name: "gestione servizi",
        component: () =>
          import("@/app/modules/admin/views/AdminHomeServices.vue"),
      },

      {
        path: "prenotazioni",
        name: "servizio facile",
        component: () =>
          import("@/app/modules/admin/views/GestioneServizioPrenotazioni.vue"),
      },
      {
        path: "teleconsulto",
        name: "servizio di teleconsulto",
        component: () =>
          import("@/app/modules/admin/views/GestioneTeleconsulto.vue"),
      },
      {
        path: "screening/:section/:action?/:quest?",
        name: "screening",
        component: () =>
          import(
            "@/app/modules/servizi/screening/views/ScreeningAdminMain.vue"
          ),
      },
      {
        path: "alerts",
        name: "admin alerts",
        component: () => import("@/app/modules/admin/views/AdminAlerts.vue"),
      },
      {
        path: "syslog",
        name: "log di sistema",
        component: () => import("@/app/modules/admin/views/LogSistema.vue"),
      },
      {
        path: "contatti",
        name: "contattipubblici",
        component: () =>
          import("@/app/modules/admin/views/AdminContattiPubblici.vue"),
      },
      {
        path: "settingsys/nuovo",
        name: "impostazioni sistema",
        component: () =>
          import("@/app/modules/admin/views/ImpostazioniSistema.vue"),
      },
      {
        path: "registrazioni",
        name: "registrazioniAdmin",
        component: () =>
          import("@/app/modules/admin/views/AdminRegistrazioni.vue"),
      },
      {
        path: "sottoscrizioni/:section",
        name: "admin sottoscrizioni",
        component: () =>
          import("@/app/modules/admin/views/MainSottoscrizioniAdmin.vue"),
      },
      {
        path: "piani",
        name: "piani asl",
        component: () =>
          import(
            "@/app/modules/servizi/pianiTerapeutici/views/PianiTerapeuticiAdminMain.vue"
          ),
      },
      {
        path: "piani/scheda/:hash",
        name: "dettaglio pt asl",
        component: () =>
          import(
            "@/app/modules/servizi/pianiTerapeutici/views/PianoTerapeuticoDetailAdmin.vue"
          ),
      },
    ],
  },
  {
    path: "/*",
    name: "pagina non trovata",
    component: NotFoundPage,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/register",
    "/password/reset",
    "/password/recovery",
    "/disabled",
    "/email/verify/already",
    "/email/verify/success",
    // "/help",
  ];

  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  if (to.name === "PaymentOutcome") {
    return next();
  }

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  if (!authRequired && loggedIn) {
    return next("/");
  }

  next();
});
router.afterEach((to, from) => {
  window.scrollTo(0, 0);
});

export default router;
