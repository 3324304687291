<template>
  <b-modal
    id="popup_notification_modal"
    size="md"
    title-tag="h4"
    title-class="text-center w-100"
    header-border-variant="white"
    :header-text-variant="modalVariant"
    :title="modalTitle"
    @hide="!alreadySeen ? maskAsSeen() : null"
    centered
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
  >
    <!-- <template #modal-header>
      <h4 :class="'text-' + modalVariant" class="text-center w-100">
        {{ modalTitle }}
      </h4>
    </template> -->
    <div v-if="shownNotification" class="p-2">
      <!-- :class="'text-' + modalVariant" -->
      <h6 class="text-center">
        {{ shownNotification.body }}
      </h6>

      <p class="text-center mb-0">
        <b-btn
          :variant="modalVariant"
          @click="doNotificationAction(shownNotification)"
          class="mt-4 text-center"
        >
          {{ shownNotification.action }}
        </b-btn>
      </p>
    </div>
  </b-modal>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { popupService } from "@/_services";
export default {
  name: "PopupNotificationComponent",
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    modalTitle: function () {
      if (this.shownNotification) {
        return this.shownNotification.title;
      }
      return "";
    },
    modalVariant: function () {
      if (this.shownNotification) {
        return this.shownNotification.variant;
      }
      return "secondary";
    },
    alreadySeen: function () {
      return this.shownNotification ? this.shownNotification.seen : true;
    },
  },
  watch: {
    notifications: function (value) {
      if (!this.shownNotification && value.length > 0) {
        this.shownNotification = value[0];
        this.$bvModal.show("popup_notification_modal");
      }
    },
  },
  data() {
    return {
      notifications: [],
      pollingTimeout: null,
      shownNotification: null,
      actionTextToRoutesMap: {
        "Vai al Teleconsulto Rapido": {
          name: "teleconsulto",
          params: { section: "rapido" },
        },
        "Vai al Teleconsulto Specialistico": {
          name: "teleconsulto",
          params: { section: "specialistico" },
        },
        "Vai al Teleconsulto Pedriatico": {
          name: "teleconsulto",
          params: { section: "pediatrico" },
        },
      },
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
      openAndBlock: "modal/openAndBlock",
      clearModal: "modal/clear",
    }),
    fetchData() {
      const self = this;
      popupService
        .getUserPopupNotifications({ unseen: true })
        .then(function (res) {
          self.notifications = res.data.data;
        })
        .catch(function (err) {
          self.errorAlert(
            "Non è stato possibile verificare la presenza di nuove notifiche popup"
          );
        });
    },
    maskAsSeen() {
      const self = this;
      return popupService
        .markPopupNotificationAsSeen(this.shownNotification?.hashid)
        .then(function (res) {
          self.shownNotification = null;
          return res;
        })
        .catch(function (err) {
          self.errorAlert(
            "Non è stato possibile aggiornare lo stato della notifica popup"
          );
          throw err;
        });
    },
    setPollingTimeout() {
      const self = this;
      this.pollingTimeout = setInterval(function () {
        if (!self.shownNotification) {
          self.fetchData();
        }
      }, 4500);
    },
    doNotificationAction(notification) {
      const actionLower = notification.action?.toLowerCase();
      const matchingRoute = Object.entries(this.actionTextToRoutesMap).find(
        function ([key]) {
          return key.toLowerCase() === actionLower;
        }
      );

      if (matchingRoute) {
        const self = this;

        this.maskAsSeen().then(function () {
          self.$bvModal.hide("popup_notification_modal");

          const currentRoute = self.$route;
          const newRoute = matchingRoute[1];

          // Controlla se la route è la stessa e ha gli stessi parametri
          if (
            currentRoute.name !== newRoute.name ||
            currentRoute.params.section !== newRoute.params.section
          ) {
            // Navighiamo solo se la route è diversa
            self.$router.push(newRoute);
          }
        });
      } else {
        console.log("Nessuna route trovata per l'azione:", notification.action);
      }
    },
  },
  created() {
    this.setPollingTimeout();
  },
  beforeDestroy() {
    clearInterval(this.pollingTimeout);
  },
};
</script>
