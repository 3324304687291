
const state = {
  hasFilledSurvey: false,
  mustFillSurvey: false
}

const actions = {
  setHasFilledSurvey({ commit }, status) {
    commit("setHasFilledSurvey", status)
  },
  setMustFillSurvey({ commit }, status) {
    commit("setMustFillSurvey", status)
  },
  clear({ commit }) {
    commit("clear")
  }
}

const mutations = {
  setHasFilledSurvey(state, status) {
    state.hasFilledSurvey = status
  },
  setMustFillSurvey(state, status) {
    state.mustFillSurvey = status
  },
  clear(state) {
    state.hasFilledSurvey = false
    state.mustFillSurvey = false
  }
}

export const tcsurvey = {
  namespaced: true,
  state,
  actions,
  mutations
}
