<template>
  <b-modal
    :id="`modal-somministra-tc-custom-${serviceName}`"
    v-model="isModalVisible"
    :title="`Compila il questionario per il Teleconsulto ${titleName[serviceName]} (la compilazione è obbligatoria)`"
    hide-footer
    centered
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    aria-modal="true"
  >
    <div
      class="p-4 mx-auto"
      style="max-width: 1200px"
      v-if="suspendable && !showDone"
    >
      <div v-if="survey && !complete">
        <section class="mb-5">
          <h4 class="text-prim-grad-1 text-center fw-bolder text-left pb-1">
            {{ survey.name }}
          </h4>
          <p class="text-justify text-center text-medium-grey fw-bolder">
            {{ survey.description }}
          </p>
        </section>
        <div v-if="survey.info">
          <div v-if="!proceed">
            <p
              class="text-justify mt-2 fs-5 informativa__p"
              v-html="survey.info"
            ></p>
            <b-form-checkbox
              class="text-font-light-grey pb-4 mb-3"
              name="data_consense"
              :value="true"
              :unchecked-value="false"
              v-model="consent"
              :state="consent == true"
            >
              Ho letto l'informativa e do il mio consenso
            </b-form-checkbox>
            <b-button
              variant="secondary text-white fw-bolder spacing_1"
              :disabled="!consent"
              @click="proceed = true"
              >Procedi</b-button
            >
          </div>
          <div v-else>
            <FillCustomTeleconsultoSurvey
              :survey="survey.template"
              :afteres="true"
              v-on:end-quest="handleFilledSurvey($event)"
            />
          </div>
        </div>
        <div v-else>
          <FillCustomTeleconsultoSurvey
            :survey="survey.template"
            :afteres="true"
            v-on:end-quest="handleFilledSurvey($event)"
          />
        </div>
      </div>
    </div>
    <div class="mt-5 shadowedCard p-4" v-else-if="showDone">
      <h5>Hai già compilato il questionario.</h5>
      <router-link :to="{ name: 'agenda' }">
        <b-button variant="secondary spacing_1 m-2">Vai all'agenda</b-button>
      </router-link>
    </div>
  </b-modal>
</template>

<script>
import {
  supportService,
  cittadinoService,
  reservationService,
} from "@/_services";
import { mapState, mapActions } from "vuex";
import FillCustomTeleconsultoSurvey from "@/app/modules/servizi/teleconsulto/components/FillCustomTeleconsultoSurvey.vue";

export default {
  name: "SomministraCustomTeleconsultoSurvey",
  components: {
    FillCustomTeleconsultoSurvey,
  },
  props: ["serviceName"],
  computed: {
    ...mapState({
      alert: (state) => state.alert,
      mustFillSurvey: (state) => state.tcsurvey.mustFillSurvey,
    }),
    ...mapState("utente", ["status", "user"]),
    isModalVisible: {
      get() {
        return this.mustFillSurvey;
      },
      set(value) {
        if (!value) {
          this.setMustFillSurvey(false);
        }
      },
    },
  },
  data() {
    return {
      survey: null,
      consent: false,
      proceed: false,
      complete: false,
      suspendable: null,
      showDone: false,
      titleName: {
        tc_pediatrico: "Pediatrico",
        emergenza: "Rapido",
      },
    };
  },
  watch: {
    mustFillSurvey(value) {
      if (value) {
        this.openAndBlock(true);
        this.fetchData();
      }
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
      setHasFilledSurvey: "tcsurvey/setHasFilledSurvey",
      setMustFillSurvey: "tcsurvey/setMustFillSurvey",
      clearTcsurvey: "tcsurvey/clear",
      openAndBlock: "modal/openAndBlock",
      clearModal: "modal/clear",
    }),
    fetchData() {
      const self = this;
      reservationService
        .getUserPendingCustomTcReservation(this.serviceName)
        .then(function (res) {
          if (
            res.data.data.has_pending &&
            res.data.data.reservation &&
            res.data.data.reservation.suspended_survey
          ) {
            self.getSuspendedSurvey(
              res.data.data.reservation.suspended_survey.hashid
            );
          }
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          self.clearModal();
        });
    },
    getSuspendedSurvey(surveyHashid) {
      const self = this;
      supportService
        .getSuspendedSurveyById(surveyHashid)
        .then(function (surveyRes) {
          self.survey = surveyRes.data.data.survey;
          self.suspendable = surveyRes.data.data;
          self.consent = surveyRes.data.data.survey.info == null;
        })
        .catch(function (err) {
          if (err.status == 404) {
            self.showDone = true;
            self.setHasFilledSurvey(true);
            self.clearModal();
          } else {
            window.scrollTo(0, 0);
            self.errorAlert("Non è stato possibile recuperare le attività");
            self.clearModal();
          }
        });
    },
    handleFilledSurvey(form) {
      const temp = {
        info: this.survey.info,
        nome: this.survey.name,
        domande: form,
      };
      const formData = {
        response: JSON.stringify(temp),
        suspended: this.suspendable.hashid,
      };

      const self = this;
      cittadinoService
        .storeSuspendedFilledSurvey(formData)
        .then(function (res) {
          self.complete = true;
          self.setHasFilledSurvey(true);
          self.clearTcsurvey();
          window.scrollTo(0, 0);
          self.successAlert("Questionario inviato correttamente.");
          self.$bvModal.hide(`modal-somministra-tc-custom-${self.serviceName}`);
          self.clearModal();
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile inviare il questionario");
          self.clearModal();
        });
    },
  },
  created() {
    if (this.mustFillSurvey) {
      this.openAndBlock(true);
      this.fetchData();
    }
  },
  beforeDestroy() {
    this.clearModal();
  },
};
</script>
