import config from "../_config/config";
import { authHeader, postHeader } from "../_helpers";
import axios from "axios";

axios.defaults.baseURL = config.apiUrl;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.put["X-Requested-With"] = "XMLHttpRequest";

export const companyService = {
  logUserCompanyIn,
  loginCompany,
  storeOpenings,
  storeServiceOpenings,
  updateServiceStatus,
  updateAnagrafica,
  storePrestazione,
  storeOrariPrestazione,
  updatePrestazione,
  fetchPrenotazioni,
  getCode,
  sendCode,
  getOperatorsList,
  changeOperatorStatus,
  changeOperatorRole,
  getSubscriptions,
  gotToBuy,
  getStatistics,
  storeNewAnnotation,
  updateAnnotation,
  deleteAnnotation,
  attachUploadedResourceToCompany,
  deleteCompanyPropic,
  getOwnHealthServices,
  getOwnProhibidates,
  storeNewProhibidate,
  updateProhibidate,
  changeOverlapReservationsSetting,
  storeDaysOpenings,
  getDaysOpenings,
  checkIfServiceIsActive,
  getOwnAsl,
  getOwnAslReservations,
  changeReportOption,
  getCompanyBancaDati,
  storeCompanyBancaDati,
  deleteCompanyBancaDati,
  updateCompanyBancaDati,
  updateServiceProxyStatus,
  getCompanyHealthServices,
  storeCompanyHealthServices,
  storeCompanyServicesOrari,
  updateCompanyServiceStatus,
  storeCompanyOpenings,
  getCompanyProhibidates,
  storeCompanyProhibidates,
  changeCompanyOverlapOption,
  getCompanyOpenings,
  updateOperatorServiceStatus,
  getOperatorServicesStatus,
  getCompanyArchiveFiles,
  storeCompanyArchiveFiles,
  updateCompanyArchiveFiles,
  deleteCompanyArchiveFiles,
  getUserFamilyMembers
};

function logUserCompanyIn() {
  const sett = { headers: authHeader() };
  return axios
    .get("companies/login/auth", sett)
    .then((response) => {
      if (response.data.data) {
        // console.log(response.data.data);
        localStorage.setItem("company", JSON.stringify(response.data.data));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function loginCompany(token) {
  const sett = { params: { token: token } };
  return axios
    .get("farmacia/auth", sett)
    .then((response) => {
      if (response.data.data) {
        localStorage.setItem("company", JSON.stringify(response.data.data));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeOpenings(form) {
  const sett = { headers: authHeader() };
  // console.log("sending form", form);
  return axios
    .post("companies/own/openings", JSON.stringify(form), sett)
    .then((response) => {
      if (response.data.data) {
        let old = JSON.parse(localStorage.getItem("company"));
        old.company_openings = response.data.data;
        localStorage.setItem("company", JSON.stringify(old));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeServiceOpenings(data) {
  const sett = { headers: authHeader() };
  // console.log("sending form", data.form);
  var endpoint = "services/own/openings/" + data.service_hash;
  var serviceName = data.service_name;
  return axios
    .post(endpoint, JSON.stringify(data.form), sett)
    .then((response) => {
      if (response.data.data) {
        let old = JSON.parse(localStorage.getItem("company"));
        old.servizi_attivi_orari[serviceName] = response.data.data;
        localStorage.setItem("company", JSON.stringify(old));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateServiceStatus(data) {
  const sett = { headers: authHeader() };
  var endpoint = "services/own/servicestatus/" + data.service_hash;
  const form = data.form;
  var serviceName = data.service_name;
  const serviceStatus = form.status;
  // console.log("updating status to", serviceStatus);
  return axios
    .patch(endpoint, form, sett)
    .then((response) => {
      if (response.data.data) {
        let old = JSON.parse(localStorage.getItem("company"));
        old.stato_servizi[serviceName] = serviceStatus;
        localStorage.setItem("company", JSON.stringify(old));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateAnagrafica(form) {
  const sett = { headers: authHeader() };
  return axios
    .put("companies/own/anagrafica", form, sett)
    .then((response) => {
      if (response.data.data) {
        localStorage.setItem("company", JSON.stringify(response.data.data));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storePrestazione(form) {
  const sett = { headers: authHeader() };
  return axios
    .post("healthservices", form, sett)
    .then((response) => {
      // if (response.data.data) {
      //   let old = JSON.parse(localStorage.getItem("company"))
      //   old.prestazioni_sanitarie.push(response.data.data)
      //   old.prestazioni_attive[response.data.data.name] =
      //     response.data.data.hashid
      //   localStorage.setItem("company", JSON.stringify(old))
      // }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeOrariPrestazione(data) {
  const sett = { headers: authHeader() };
  // console.log("sending form", data.form);
  var endpoint = "healthservices/own/openings/" + data.service_hash;
  var serviceName = data.service_name;
  return axios
    .post(endpoint, JSON.stringify(data.form), sett)
    .then((response) => {
      if (response.data.data) {
        let old = JSON.parse(localStorage.getItem("company"));
        old.servizi_attivi_orari[serviceName] = response.data.data;
        localStorage.setItem("company", JSON.stringify(old));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
function updatePrestazione(data) {
  const sett = { headers: authHeader() };
  var endpoint = "healthservices/" + data.service_hash;
  const form = data.form;
  return axios
    .put(endpoint, form, sett)
    .then((response) => {
      if (response.data.data) {
        // let old = JSON.parse(localStorage.getItem('company'));
        // old.stato_servizi[serviceName]=serviceStatus;
        // localStorage.setItem('company',JSON.stringify(old));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function fetchPrenotazioni(filters) {
  var sett = { headers: authHeader() };
  if (filters) {
    sett = { headers: authHeader(), params: filters };
  }
  // var endpoint = "companies/own/reservations";
  return axios
    .get("companies/own/reservations", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getCode(hash) {
  const sett = { headers: authHeader() };
  var endpoint = "companies/code/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function sendCode(data) {
  const sett = { headers: authHeader() };
  var endpoint = "companies/code/send/" + data.hash;
  return axios
    .post(endpoint, data.form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getOperatorsList(filters = {}) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("companies/operators/list", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function changeOperatorStatus(data) {
  const sett = { headers: authHeader() };
  var endpoint = "users/change/status/" + data.hash;
  return axios
    .post(endpoint, data.form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function changeOperatorRole(data) {
  const sett = { headers: authHeader() };
  var endpoint = "companies/operators/role/update";
  return axios
    .post(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getSubscriptions() {
  const sett = { headers: authHeader() };
  return axios
    .get("companies/own/subscriptions", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function gotToBuy(hash) {
  const sett = { headers: authHeader() };
  var endpoint = "companies/own/subscriptions/buy/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getStatistics() {
  const sett = { headers: authHeader() };
  var endpoint = "companies/own/statistics";
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeAnnotation($annotation) {
  const sett = { headers: authHeader() };
  return axios
    .post("annotations", $annotation, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateAnnotation($annotationHash, $annotation) {
  const sett = { headers: authHeader() };
  var endpoint = "annotations/" + $annotationHash;

  return axios
    .put(endpoint, $annotation, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeNewAnnotation(formdata) {
  const sett = { headers: authHeader() };
  return axios
    .post("annotations/", formdata, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function deleteAnnotation($annotationHash) {
  const sett = { headers: authHeader() };
  var endpoint = "annotations/" + $annotationHash;

  return axios
    .delete(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function attachUploadedResourceToCompany(hashid) {
  const sett = { headers: authHeader() };
  return axios
    .post("companies/own/profile/picture", { resource: hashid }, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function deleteCompanyPropic() {
  const sett = { headers: authHeader() };
  var endpoint = "companies/own/profile/picture/delete";
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getOwnHealthServices(filters) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("companies/own/healthservices", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getOwnProhibidates() {
  const sett = { headers: authHeader() };
  return axios
    .get("prohibidates", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeNewProhibidate(form) {
  const sett = { headers: authHeader() };
  return axios
    .post("prohibidates", form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateProhibidate(form) {
  const sett = { headers: authHeader() };
  const endpoint = "prohibidates/" + form.hashid;
  return axios
    .put(endpoint, form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function changeOverlapReservationsSetting(form) {
  const sett = { headers: authHeader() };
  const endpoint = "companies/own/option/overlap";
  return axios
    .patch(endpoint, form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeDaysOpenings(form, hash) {
  const sett = { headers: authHeader() };
  const endpoint = "healthservices/days/openings/" + hash;
  return axios
    .post(endpoint, form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getDaysOpenings(hash) {
  const sett = { headers: authHeader() };
  const endpoint = "healthservices/days/openings/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function checkIfServiceIsActive(filters) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("services/own/check/", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getOwnAsl() {
  const sett = { headers: authHeader() };
  return axios
    .get("companies/own/asl/", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getOwnAslReservations(filters = null) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("reservations/company/own", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function changeReportOption(form) {
  const sett = { headers: authHeader() };
  const endpoint = "companies/own/option/report";
  return axios
    .patch(endpoint, form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getCompanyBancaDati(filters) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("companies/own/libraries", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function storeCompanyBancaDati(form) {
  const sett = { headers: authHeader() };
  return axios
    .post("companies/own/libraries", form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function deleteCompanyBancaDati(hash) {
  const sett = { headers: authHeader() };
  const endpoint = "companies/own/libraries/" + hash;
  return axios
    .delete(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function updateCompanyBancaDati(form) {
  const sett = { headers: authHeader() };
  const endpoint = "companies/own/libraries/" + form.hashid;
  return axios
    .put(endpoint, form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function updateServiceProxyStatus(data) {
  const sett = { headers: authHeader() };
  var endpoint = "services/own/proxystatus/" + data.service_hash;
  const form = data.form;
  var serviceName = data.service_name;
  const serviceStatus = form.status;
  return axios
    .patch(endpoint, form, sett)
    .then((response) => {
      if (response.data.data) {
        let old = JSON.parse(localStorage.getItem("company"));
        old.stato_servizi[serviceName] = serviceStatus;
        localStorage.setItem("company", JSON.stringify(old));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getCompanyHealthServices(companyId, filters) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get(`companies/${companyId}/healthservices`, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeCompanyHealthServices(companyId, data) {
  const sett = { headers: authHeader() };
  return axios
    .post(`companies/${companyId}/healthservices`, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeCompanyServicesOrari(companyId, data) {
  const sett = { headers: authHeader() };
  // console.log("sending form", data.form);
  var endpoint = `companies/${companyId}/services/${data.service_hash}`;
  var serviceName = data.service_name;
  return axios
    .post(endpoint, JSON.stringify(data.form), sett)
    .then((response) => {
      if (response.data.data) {
        let old = JSON.parse(localStorage.getItem("company"));
        old.servizi_attivi_orari[serviceName] = response.data.data;
        localStorage.setItem("company", JSON.stringify(old));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateCompanyServiceStatus(companyId, data) {
  const sett = { headers: authHeader() };
  var endpoint = `companies/${companyId}/services/${data.service_hash}`;
  const form = data.form;
  var serviceName = data.service_name;
  const serviceStatus = form.status;
  return axios
    .patch(endpoint, form, sett)
    .then((response) => {
      if (response.data.data) {
        let old = JSON.parse(localStorage.getItem("company"));
        old.stato_servizi[serviceName] = serviceStatus;
        localStorage.setItem("company", JSON.stringify(old));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeCompanyOpenings(companyId, form) {
  const sett = { headers: authHeader() };
  return axios
    .post(`companies/${companyId}/openings`, JSON.stringify(form), sett)
    .then((response) => {
      if (response.data.data) {
        let old = JSON.parse(localStorage.getItem("company"));
        old.company_openings = response.data.data;
        localStorage.setItem("company", JSON.stringify(old));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getCompanyProhibidates(companyId) {
  const sett = { headers: authHeader() };
  return axios
    .get(`companies/${companyId}/prohibidates`, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeCompanyProhibidates(companyId, form) {
  const sett = { headers: authHeader() };
  return axios
    .post(`companies/${companyId}/prohibidates`, form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function changeCompanyOverlapOption(companyId, form) {
  const sett = { headers: authHeader() };
  const endpoint = `companies/${companyId}/option/overlap`;
  return axios
    .patch(endpoint, form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getCompanyOpenings(companyId) {
  const sett = { headers: authHeader() };
  return axios
    .get(`companies/openings/get/${companyId}`, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateOperatorServiceStatus(form, serviceRef) {
  const sett = { headers: authHeader() };
  const endpoint = `services/${serviceRef}/operator/status`;
  return axios
    .patch(endpoint, form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getOperatorServicesStatus(serviceRef) {
  const sett = { headers: authHeader() };
  const endpoint = `services/${serviceRef}/operator/status`;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getCompanyArchiveFiles(filters) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("companyarchives/", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeCompanyArchiveFiles(data) {
  const sett = { headers: authHeader() };
  return axios
    .post(`companyarchives/`, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateCompanyArchiveFiles(data, hashid) {
  const sett = { headers: authHeader() };
  return axios
    .put(`companyarchives/${hashid}`, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function deleteCompanyArchiveFiles(hashid) {
  const sett = { headers: authHeader() };
  return axios
    .delete(`companyarchives/${hashid}`, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}


function getUserFamilyMembers(user, filters = null) {
  const sett = { headers: authHeader(), params: filters };
  const endpoint = "familymembers/members/user/" + user;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}